import DecodingPage from './DecodingPage';

const DecodingConfig = {
//   settings: {
//     layout: {
//       config: {
//         navbar: {
//           display: false
//         },
//         topBar: {
//           display: false
//         },
//         footer: {
//           display: false
//         }
//       }
//     }
//   },
  routes: [
    {
      path: '/oferta/decoding-oddechowy',
      component: DecodingPage
    }
  ]
};

export default DecodingConfig;
