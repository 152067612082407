import { useEffect } from 'react';
import ParticleBackground from '../../layout/components/particleBackground/ParticleBackground';
import { useDocumentTags } from '../../modules/common/hooks/useDocumentTags';
import ContactForm from '../../modules/common/sections/contactForm/ContactForm';
import './MainPage.css';
import { About, Hero, Materials, Offer, Workshops } from './sections';

function MainPage() {
    const { setTitle } = useDocumentTags();

    useEffect(() => {
        setTitle("Kroniki Akaszy • Marek Żukowski");
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return (
        <>
            <ParticleBackground />
            <Hero />
            <div className='no-particles'>
                <About />
                <Offer />
                <Workshops />
                <div className="multiple-section-background">
                    <div className="materials">
                        <Materials />
                    </div>
                </div>
                <div className="contactform">
                    <ContactForm />
                </div>
            </div>
        </>
    );
}

export default MainPage;