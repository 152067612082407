import { Grid } from "@mui/material";
import { ButtonVariants } from "../../components/Button/Button";
import { BasicPaper } from "../../../../pages/main/components";
import { BasicPaperVariants } from "../../../../pages/main/components/BasicPaper";
import "./ContactForm.css";
import clsx from "clsx";

interface IProps {
    isEventPage?: boolean;
    isNoMargin?: boolean;
}

function ContactForm({isEventPage, isNoMargin}: IProps) {
    return (
        <section className="contact" id="kontakt">
            <div className={clsx((isNoMargin ? "pt-0" : "pt-5"), "container")}>
                <Grid container spacing={0}>
                    <Grid item md={3}>
                        <BasicPaper
                            variant={BasicPaperVariants.CONTACT_FORM_INFORMATION}
                            buttonVariant={ButtonVariants.SECONDARY}
                            title="Informacje kontaktowe"
                        />
                    </Grid>
                    <Grid item md={5}>
                        <BasicPaper
                            variant={BasicPaperVariants.CONTACT_FORM}
                            buttonVariant={ButtonVariants.SECONDARY}
                            // title={isEventPage ? "Zapisy na warsztat" : "Formularz kontaktowy"}
                            title={"Formularz kontaktowy"}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        {/* <div data-aos="fade-up"> */}
                            <iframe
                                data-aos="fade-up"
                                title="test" 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2459.7554802812865!2d20.489155699999998!3d51.9384135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471941e30e899761%3A0x828f68c87dc9139e!2sStyropianowa%2017%2C%2096-320%20Adamowice!5e0!3m2!1spl!2spl!4v1667040541665!5m2!1spl!2spl" 
                                loading="lazy">
                            </iframe>
                        {/* </div> */}
                    </Grid>
                </Grid>
            </div>
        </section>
    );
}

export default ContactForm;