import { Divider, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ButtonVariants } from "../../../modules/common/components/Button/Button";
import { BasicPaper } from "../components";
import { BasicPaperVariants } from "../components/BasicPaper";
import Text from "../../../modules/common/components/Text/Text";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import clsx from "clsx";

interface IProps {
    isMainPage?: boolean;
}

function Offer({ isMainPage }: IProps) {
    const history = useHistory();
    const body = document.querySelector('#root');
    
    const navigateTo = (page: string) => {
        body?.scrollIntoView();
        history.push(page);
    }

    return (
        <div className={clsx(!isMainPage ? "offer-elements container" : "")}>
            <Swiper
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 50,
                    },
                    1000: {
                        slidesPerView: 2,
                        spaceBetween: 50,
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                    1400: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                    1600: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                    2000: {
                        slidesPerView: 5,
                        spaceBetween: 50
                    }
                }}
                modules={[Navigation]}
                grabCursor
                navigation
                loop
            >
                <SwiperSlide>
                    <BasicPaper
                        imgSrc='/assets/images/kroniki.webp'
                        imgAlt="Oferta Czytania z Kronik Akaszy"
                        variant={BasicPaperVariants.OFFER}
                        buttonVariant={ButtonVariants.PURPLE}
                        title="Czytanie&nbsp;z&nbsp;Kronik Akaszy"
                        description="Kroniki Akaszy to energetyczny zapis. To każda nasza myśl, czyn, emocja. To Każdy wniosek przez nas wysnuty, który kiedykolwiek pojawił się w naszym czasie i przestrzeni (włącznie z wędrówka duszy)."
                        onClick={() => navigateTo('/oferta/kroniki-akaszy')} 
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <BasicPaper
                        imgSrc='/assets/images/bioenergoterapia.webp'
                        imgAlt="Oferta Bioenergoterapii"
                        variant={BasicPaperVariants.OFFER}
                        buttonVariant={ButtonVariants.BLUE}
                        title="Bioenergoterapia"
                        description="Bioenergoterapia to jedna z metod naturalnych prowadząca do polepszenia stanu zdrowia. Obszarem jej oddziaływania jest energetyczny system człowieka, jest on podobny do krwionośnego, lecz istnieje nie na planie fizycznym. Jest subtelną naturą energetyczną. W skład tego układu wchodzą czakry, czyli centra energetyczne."
                        onClick={() => navigateTo('/oferta/bioenergoterapia')} 
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <BasicPaper
                        imgSrc='/assets/images/radiestezja.webp'
                        imgAlt="Oferta Radiestezji"
                        variant={BasicPaperVariants.OFFER}
                        buttonVariant={ButtonVariants.PINKNGREEN}
                        title="Radiestezja"
                        description="Radiestezja jest obecna w życiu człowieku od 6000 lat przed naszą erą. Obejmowała ona Chiny, Indie, Egipt oraz Amerykę Przedkolumbijską. W Polsce datowanie najstarszego dokumentu o zastosowaniu różdżki to zapis z 1450 roku. W tamtym czasie była ona wykorzystywana do poszukiwania wody, kruszców oraz jak też rzeczy zagubionych"
                        onClick={() => navigateTo('/oferta/radiestezja')} 
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <BasicPaper
                        imgSrc='/assets/images/decoding.webp'
                        imgAlt="Oferta Decodingu Oddechowego"
                        variant={BasicPaperVariants.OFFER}
                        buttonVariant={ButtonVariants.YELLOW}
                        title="Decoding oddechowy"
                        description="Sesje, które prowadzę są skierowane do osób objętych: strachem, poczuciem winy, wstydem, zachłannością, kłamstwem, iluzją lub obsesyjnym przywiązaniem. Jest to 7 głównych zakłóceń, które mogą pojawić się chwilowo w naszym życiu lub rozgościć się na długie lata."
                        onClick={() => navigateTo('/oferta/decoding-oddechowy')} 
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <BasicPaper
                        imgSrc='/assets/images/ustawienia.webp'
                        imgAlt="Oferta Ustawień Systemowych"
                        variant={BasicPaperVariants.OFFER}
                        buttonVariant={ButtonVariants.ORANGE}
                        title="Ustawienia systemowe"
                        description="Można porównać „egregor” systemowy do chmury, która nad nami wisi i rzuca cień nieustannie powodując, że w nas samych pobudza się chęć odwzajemnienia nastroju chmury."
                        onClick={() => navigateTo('/oferta/ustawienia-systemowe')} 
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <BasicPaper
                        imgSrc='/assets/images/regresja.webp'
                        imgAlt="Oferta Regresji Hipnotycznej"
                        variant={BasicPaperVariants.OFFER}
                        buttonVariant={ButtonVariants.BLUE}
                        title="Regresja hipnotyczna"
                        description="Jedna z naturalnych technik terapeutycznych, która ma na celu dotarcie do podświadomości, gdzie zapisana jest trauma związana z losowym zdarzeniem."
                        onClick={() => navigateTo('/offer/regresja-hipnotyczna')} 
                    />
                </SwiperSlide>
            </Swiper>
            <div data-aos="fade-up" className="pb-5">
                <Grid container spacing={3} className="container pt-2">
                    <Grid item className="w-50 ml-25">
                        <Divider />
                    </Grid>
                    <Grid item className="d-flex justify-content-center w-full">
                        <Text variant="p" color="dark" isTextCenter>
                            W celu umówienia wolnego terminu zachęcamy do skrzystania z Facebook'a lub Whatsapp'a.
                        </Text>
                    </Grid>
                    <Grid container className="container pt-2">
                        <Grid container>
                            <Grid item md={1.5} lg={3} xl={4.5}></Grid>
                            <Grid item xs={4} md={3} lg={2} xl={1}>
                                <Text variant="h4" color="dark" isBold isTextCenter>
                                    Marek
                                </Text><br />
                                <Text variant="h4" color="dark" isBold isTextCenter>
                                    <a href="https://wa.me/48505072849">
                                        <img src="/assets/images/whatsapp.svg" alt="Whatsapp" />
                                    </a>
                                </Text>
                            </Grid>
                            <Grid item xs={4} md={3} lg={2} xl={1}>
                                <Text variant="h4" color="dark" isBold isTextCenter>
                                    &nbsp;
                                </Text><br />
                                <Text variant="h4" color="dark" isBold isTextCenter>
                                    <a href="https://www.facebook.com/profile.php?id=100076264266960">
                                        <img src="/assets/images/facebook.svg" alt="FaceBook" />
                                    </a>
                                </Text>
                            </Grid>
                            <Grid item xs={4} md={3} lg={2} xl={1}>
                                <Text variant="h4" color="dark" isBold isTextCenter>
                                    Danuta
                                </Text><br />
                                <Text variant="h4" color="dark" isBold isTextCenter>
                                    <a href="https://wa.me/48500193207">
                                        <img src="/assets/images/whatsapp.svg" alt="Whatsapp" />
                                    </a>
                                </Text>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Offer;