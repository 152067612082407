import NonPersonalizedReadingPage from './NonPersonalizedReadingPage';

const NonPersonalizedReadingConfig = {
  routes: [
    {
      path: '/materialy/odczyty-niespersonalizowane',
      component: NonPersonalizedReadingPage
    }
  ]
};

export default NonPersonalizedReadingConfig;
