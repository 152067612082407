import { Grid } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { Page, Text } from "../../modules/common/components";
import { ButtonVariants } from "../../modules/common/components/Button/Button";
import { useWorkshopQuery } from "../../modules/workshops/api/queries/useWorkshopQuery";
import { BasicPaper } from "../main/components";
import { BasicPaperVariants } from "../main/components/BasicPaper";

function EventsPage() {
    const history = useHistory();
    const params = useParams();
    const body = document.querySelector('#root');
    const { data } = useWorkshopQuery(params);
    
    const navigateTo = (page: string) => {
        body?.scrollIntoView();
        history.push(page)
    }

    const renderWorkshops = () => {
        return data?.count === 0 ? (
            <Grid item md={12}>
                <Text variant="p" color="dark">
                    Brak dostępnych wyjazdów :(
                </Text>
            </Grid>
        ) : (
            data?.rows?.map((workshop: any) => {
                return (
                    <Grid item md={3}>
                        <BasicPaper
                            variant={BasicPaperVariants.EVENTS}
                            buttonVariant={ButtonVariants.PRIMARY}
                            title={workshop.title}
                            localization={`${workshop.address} ${workshop.city} ${workshop.postcode}, ${workshop.country}`}
                            starDate={workshop.startDate}
                            finishDate={workshop.finishDate}
                            onClick={() => navigateTo(`/warsztat/wyjazd/${workshop.id}`)}
                        />
                    </Grid>
                )
            })
        );
    };

    return (
        <Page title={'Lista wyjazdów'} isContactFormHidden>
            <Grid container spacing={3}>
                {renderWorkshops()}
            </Grid>
        </Page>
    ) 
}

export default EventsPage;