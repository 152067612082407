import { useMemo } from "react";
import { Page } from "../../modules/common/components";
import { Link, useHistory } from "react-router-dom";
import { BasicPaper } from "../main/components";
import { BasicPaperVariants } from "../main/components/BasicPaper";
import { ButtonVariants } from "../../modules/common/components/Button/Button";
import { useWorkshopsQuery } from "../../modules/workshops/api/queries/useWorkshopsQuery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

function WorkshopsPage() {
    const history = useHistory();
    const body = document.querySelector('#root');
    const { isLoading, data } = useWorkshopsQuery();
    
    const navigateTo = (page: string) => {
        body?.scrollIntoView();
        history.push(page)
    }

    const renderWorkshops = () => {
        return data ?? !isLoading ? (
            data.map((workshop: any) => {
                return (
                    <SwiperSlide>
                        <BasicPaper
                            imgSrc={workshop?.imageUrl}
                            imgAlt="Oferta Czytania z Kronik Akaszy"
                            variant={BasicPaperVariants.WORKSHOPS}
                            buttonVariant={ButtonVariants.PRIMARY}
                            title={workshop?.title}
                            description={workshop?.description}
                            onClick={() => navigateTo(`/warsztat/${workshop?.id}`)}
                            isHighlighted={workshop?.isHighlighted}
                        />
                    </SwiperSlide>
                )
            })
        ) : null;
    };

    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                &nbsp;/&nbsp;Warsztaty
            </>
        )
    }, []);

    return (
        <Page title={title}>
            <Swiper
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 50,
                    },
                    1000: {
                        slidesPerView: 2,
                        spaceBetween: 50,
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    }
                }}
                modules={[Navigation]}
                grabCursor
                navigation
                loop
            >
                {renderWorkshops()}
            </Swiper>
        </Page>
    );
}

export default WorkshopsPage;