import { Typography } from "@mui/material";
import './Text.css';
import clsx from 'clsx';

interface IProps {
    variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'breadcrumbs',
    color: 'bright' | 'dark',
    isBold?: boolean;
    isTextCenter?: boolean;
    isUnderline?: boolean;
    isPadding?: boolean;
    children: React.ReactNode;
}

function Text({ variant, color, isBold, isTextCenter, isUnderline, isPadding=true, children }: IProps) {
    const isTextBold = isBold ? 'bold' : 'none';
    const isCenter = isTextCenter  ? 'center' : 'left';
    const isTextUnderline = isUnderline ? 'underline' : 'none';
    const isTextPadding = isPadding ? 'none' : 'no-padding';
    
    return <Typography className={clsx(variant, color, isTextBold, isCenter, isTextUnderline, isTextPadding)}>{children}</Typography>
}

export default Text;