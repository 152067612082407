import { TextField, TextFieldProps } from '@mui/material';

function TextInput({
  inputRef,
  label,
  className,
  InputProps,
  helperText,
  'aria-label': ariaLabel,
  isWarning,
  isLabelHidden,
  isDeleteEnabled,
  ...forwardProps
}: TextFieldProps & { isWarning?: boolean; isDeleteEnabled?: boolean; isLabelHidden?: boolean }) {

  return (
      <TextField
      variant="standard"
      label={label}
        inputRef={inputRef}
        {...forwardProps}
        InputProps={{
          ...(typeof label === 'string' ? { 'aria-label': label } : { 'aria-label': ariaLabel }),
          ...InputProps,
        }} 
      />
  );
}

export default TextInput;
