import { Grid } from "@mui/material";
import Text from '../../../modules/common/components/Text/Text';

function Hero() {
    return (
        <section className="hero d-flex">
            <Grid container spacing={3} className="container align-items-center">
                <Grid item md={6} xs={12}>
                    <div data-aos="fade-up">
                        <Text variant="h1" isBold color="bright">
                            Stwórco... <br />Jestem Ci wdzięczny za Dar... <br />Za życie moje, pełne wspaniałych niespodzianek. <br />Amen.
                        </Text>
                    </div>
                    <div className="align-center-on-mobile" data-aos="fade-up">
                        <Text variant="h2" color="bright">
                            Kroniki Akaszy - 20.10.2019 r. <br />Marek Żukowski
                        </Text>
                    </div>
                </Grid>
            </Grid>
        </section>
    );
}

export default Hero;