import EventsDetailsPage from './EventsDetailsPage';

const EventsDetailsConfig = {
  routes: [
    {
      path: '/warsztat/wyjazd/:id',
      exact: true,
      component: EventsDetailsPage
    }
  ]
};

export default EventsDetailsConfig;
