import { Grid } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Page, Text } from "../../../modules/common/components";

function DecodingPage() {
    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                <Link to="/oferta" className="white-font">
                &nbsp;/&nbsp;Oferta
                </Link>
                &nbsp;/&nbsp;Decoding oddechowy
            </>
        )
    }, []);

    return (
        <Page title={title}>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Dla kogo sesje?
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Sesje, które prowadzę są skierowane do osób objętych : STRACHEM, POCZUCIEM WINY, WSTYDEM, ZACHŁANNOŚCIĄ, KŁAMSTWEM, ILUZJĄ LUB OBSESYJNYM PRZYWIĄZANIEM. Jest to 7 głównych zakłóceń, które mogą pojawić się chwilowo w naszym życiu lub rozgościć się na długie lata. Niektóre z nich pochodzą nawet z naszych poprzednich wcieleń lub linii rodowych. W tym drugim przypadku są więc „nasze” tylko poprzez „zasiedzenie” rodowe, a więc wcale „nie nasze”. Od tych siedmiu głównych wywodzi się mnóstwo emocji pochodnych i to właśnie z nimi pracujemy w czasie sesji. Powiązane są grupowo z każdą z czakr, czyli centrum energetycznym. Za emocją, która jest niejako „kajdanami” naszej mocy kreacji idą dolegliwości fizyczne, które dodatkowo zakłócają nasze funkcjonowanie. Jak łatwo jest zaobserwować, zależność pomiędzy przewlekłymi chorobami i przewlekłymi stanami niepokoju. Drogą uwolnienia jest też oddech, a nie leczenie ciała. Czyli uwalniając w oddechu emocje uleczamy ciało fizyczne samoczynnie i skutecznie. Skutkiem ubocznym 😊 tego procesu jest to że nasz świat zewnętrzny, który jest naszym „lustrem” pokazuje nam natychmiast co mamy, a raczej czego udało nam się pozbyć z naszego wnętrza. I tak na przykład: uwalniając się z POCZUCIA WINY, świat przestaje nas obciążać za coś czego wcale nie zrobiliśmy. Puszczając na przykład ILUZJĘ, świat przestanie pokazywać nam obietnice, które nigdy się nie spełniają.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Zamykając wszystkie sesje uwalniające, wpisujemy w wolną przestrzeń algorytmy pozytywnego myślenia i działania, aby cała moc kreacji w nas mogła się uruchomić 😁 Wszystkie sesje są prowadzone indywidualnie, więc trudno powiedzieć ile ich potrzeba… minimum 2 ale czasem 5 ( po około 1,5 do 2 godzin). W trakcie ich trwania są uwolnienia emocji z podświadomości (ciała subtelne), a więc pojawiają się odczucia w ciele lub obrazy, odpowiedzi skąd dana emocja pochodzi. Jakie jest jej źródło? Pierwsza sesja jest analizą obecności emocji, którą prowadzimy wspólnie, dalej klient(ka) mając wgląd w swoją problematykę, może skorzystać z mojego prowadzenia sesji w połączeniu z Kronikami Akaszy lub wykonać uwolnienie sam(a). Chętnie udzielę wskazówek. Każdy rodzaj pracy nad rozwojem swojej świadomości to krok bliżej osiągnięcia równowagi w energiach, a co za tym idzie, równowagi w naszym rzeczywistym świecie 🍀
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Technika prowadzenia naszych sesji jest oparta na wiedzy i doświadczeniu pochodzącej z kultury Dalekiego Wschodu. Zwrócenie się ku samemu sobie to klucz: bo choćbyś zwiedził(a) cały świat, a nie odbyłeś(aś) podróży w głąb siebie i swoich emocji to tak jakbyś nie był(a) nigdzie 😁
                        </Text>
                    </div>
                </Grid>
            </Grid>
        </Page>
    )
}

export default DecodingPage;