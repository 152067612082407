import { useMutation } from "react-query";
import API from "../../../../api/endpoints";

function postMessage(formData: any) {
    return API.contactForm.postMessage(formData);
  }
  
  function useContactFormMutation(props: any) {
    return useMutation(postMessage, props);
  }
  
  export default useContactFormMutation;
  