import { Alert, CircularProgress, Grid, ImageList, ImageListItem, Paper, Snackbar } from "@mui/material";
import './Components.css';
import Text from '../../../modules/common/components/Text/Text';
import Button, { ButtonVariants } from "../../../modules/common/components/Button/Button";
import { useMemo, useState } from "react";
import moment from 'moment';
import Socialmedia from "../../../layout/components/socialmedia/Socialmedia";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FormV2Context from "../../../modules/common/formsV2/context/FormV2Context";
import { useForm } from "react-hook-form";
import { typedNameV2 } from "../../../modules/common/formsV2/context/utils";
import TextInputField from "../../../modules/common/form/TextInputField";
import useContactFormMutation from "../../../modules/notifications/api/mutations/useContactFormMutation";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface IProps {
    variant?: 'about' | 'offer' | 'workshops' | 'materials' | 'contact_form_information' | 'contact_form' | 'events' | 'event' | 'films';
    title: string;
    description?: string;
    buttonVariant?: string;
    imgSrc?: any;
    imgAlt?: string;
    isHighlighted?: boolean;
    localization?: React.ReactNode;
    starDate?: string;
    finishDate?: string;
    price?: number;
    schedule?: any;
    filmUrl?: string;
    onClick?: () => void;
}

export enum BasicPaperVariants {
    ABOUT = 'about',
    OFFER = 'offer',
    WORKSHOPS = 'workshops',
    MATERIALS = 'materials',
    CONTACT_FORM_INFORMATION = 'contact_form_information',
    CONTACT_FORM = 'contact_form',
    EVENTS = 'events',
    EVENT = 'event',
    FILMS = 'films'
}

function convertDateToDateTimeFormat(date: any) {
    return (date ? moment.utc(date).format('DD.MM.YYYY, HH:mm') : date) as string;
  }

function BasicPaper({variant, title, description, buttonVariant, imgSrc, imgAlt, isHighlighted, localization, starDate, finishDate, price, schedule, filmUrl, onClick}: IProps) {
    const validationSchema = yup.object({
        clientName: yup.string().required(),
        clientEmail: yup.string().email().required(),
        topic: yup.string().required(),
        content: yup.string().required(),
        recipientName: yup.string().required()
      });

    const form = useForm<Record<string, any>>({
        resolver: yupResolver(validationSchema)
    });
    const {
        watch,
        getValues,
        reset
      } = form;
    const values = useMemo(() => ({ ...form, watch, getValues, reset }), [
        form,
        watch,
        getValues,
        reset
    ]);

    const [open, setOpen] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    const [sendForm] = useContactFormMutation({
        onSuccess: () => {
            setOpen(true);
        }
      });

    const handleSubmit = (formData: any) => {
        setLoading(true);
        sendForm(
            { 
                clientName: formData.clientName,
                clientEmail: formData.clientEmail,
                topic: formData.topic,
                content: formData.content,
                recipientName: formData.recipientName
            }
        ).finally(()=>{
            reset();
            setLoading(false);

        })
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
    };

    if (variant === BasicPaperVariants.ABOUT) {
        return (
            <div data-aos="fade-up">
                <Paper elevation={3} className="basic-paper">
                    <Text variant="h3" color="dark">
                        {title}
                    </Text>
                    <Text variant="p" color="dark">
                        {description}
                    </Text>
                </Paper>
            </div>
        );
    } else if (variant === BasicPaperVariants.OFFER) {
        return (
            <div data-aos="fade-up">
                <div className="w-full d-flex justify-content-center py-1">
                    <img src={imgSrc} alt={imgAlt} className="basic-paper-image" />
                </div>
                <Paper elevation={3} className="basic-paper pb-7">
                    <br />
                    <Text variant="h4" color="dark" isBold isTextCenter>
                        {title}
                    </Text>
                    <div className="px-2">
                        <Text variant="p" color="dark" isTextCenter>
                            {description}
                        </Text>
                    </div>
                </Paper>
                <Button variant={buttonVariant} isFullWidth onClick={onClick}>
                    <Text variant="p" color="dark" isBold>
                        Szczegóły
                    </Text>
                </Button>
            </div>
        );
    } else if (variant === BasicPaperVariants.WORKSHOPS) {
        return (
            <div className={isHighlighted ? 'highlighted' : 'none'} data-aos="fade-up">
                <Paper elevation={3} className="basic-paper">
                    <Grid container spacing={3} className="align-items-end px-2">
                        <Grid item xl={5}>
                            <img src={imgSrc} alt={imgAlt} className="workshops-image w-full h-full" />
                        </Grid>
                        <Grid item xl={7}>
                            <Text variant="h4" color="dark" isBold>
                                "{title}"
                            </Text>
                        </Grid>
                        <Grid item md={12}>
                            <Text variant="p" color="dark">
                                {description}
                            </Text>
                        </Grid>
                        <Grid item md={12}>
                            <Button variant={buttonVariant} onClick={onClick}>
                                <Text variant="p" color="bright" isBold>
                                    Lista wyjazdów
                                </Text>
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    } else if (variant === BasicPaperVariants.MATERIALS) {
        return (
            <div data-aos="fade-up">
                <Paper elevation={3} className="basic-paper">
                    <Grid container spacing={3} className="px-2">
                        <Grid item xl={12}>
                            <Text variant="h4" color="dark" isBold>
                                "{title}"
                            </Text>
                        </Grid>
                        <Grid item md={12}>
                            <Text variant="p" color="dark">
                                {description}
                            </Text>
                        </Grid>
                        <Grid item md={12}>
                            <Button variant={buttonVariant} onClick={onClick} isOutline>
                                <Text variant="p" color="dark" isBold>
                                    Czytaj dalej
                                </Text>
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    } else if (variant === BasicPaperVariants.CONTACT_FORM_INFORMATION) {
        return (
            <div className="h-full" data-aos="fade-up">
                <Paper elevation={3} className="basic-paper contact-form h-full">
                    <Grid container spacing={3} className="d-flex h-full">
                        <Grid item md={12}>
                            <Text variant="h4" color="bright" isBold>
                                {title}
                            </Text>
                        </Grid>
                        <Grid item md={12}>
                            <Text variant="p" color="bright">
                                <div className="mail d-flex">
                                    <MailOutlineIcon />
                                    <a href="mail:kontakt@kroniki-akaszy.info">
                                        <span>kontakt@kroniki-akaszy.info</span>
                                    </a>
                                </div>
                            </Text>
                            <Text variant="p" color="bright">
                                <strong>Marek</strong><br />
                                <div className="phone d-flex">
                                    <PhoneIphoneIcon />
                                    <a href="tel:+48505072849">
                                        <span>+48 505 072 849</span>    
                                    </a> 
                                </div>
                            </Text>
                            <Text variant="p" color="bright">
                                <strong>Danka</strong><br />
                                <div className="phone d-flex">
                                    <PhoneIphoneIcon /> 
                                    <a href="tel:+48500193207">
                                        <span>+48 500 193 207</span>
                                    </a>
                                </div>
                            </Text>
                        </Grid>
                        <Grid item md={12}>
                            <Socialmedia />
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    } else if (variant === BasicPaperVariants.CONTACT_FORM) {
        return (
            <div>
            <Snackbar open={open} autoHideDuration={10000} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} className="snack">
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Wysłano pomyślnie
                </Alert>
            </Snackbar><div className="h-full" data-aos="fade-up">
                    <Paper elevation={3} className="basic-paper h-full">
                        <FormV2Context.Provider value={values}>
                            <Grid container spacing={3}>
                                <Grid item xl={12}>
                                    <Text variant="h4" color="dark" isBold>
                                        {title}
                                    </Text>
                                </Grid>
                                <Grid item md={12}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} className="w-full">
                                            <TextInputField
                                                name={typedNameV2<any>('clientName')}
                                                label={"Imię i nazwisko"}
                                                isRequired />
                                        </Grid>
                                        <Grid item md={6} className="w-full">
                                            <TextInputField
                                                name={typedNameV2<any>('clientEmail')}
                                                label={"Adres email"}
                                                isRequired />
                                        </Grid>
                                        <Grid item md={6} className="w-full">
                                            {/* <TextField
        label="Adresat"
        fullWidth
        select
        value={currency}
        onChange={(e: any) => handleChange(e)}
        SelectProps={{
            native: true,
        }}
        helperText="Kliknij, aby wybrać adresata"
        variant="standard"
    >
        {recipients.map((option) => (
            <option key={option.value} value={option.value}>
                {option.label}
            </option>
        ))}
    </TextField> */}
                                            <TextInputField
                                                name={typedNameV2<any>('recipientName')}
                                                label={"Adresat - Marek lub Danka"}
                                                isRequired />
                                        </Grid>
                                        <Grid item md={6} className="w-full">
                                            <TextInputField
                                                name={typedNameV2<any>('topic')}
                                                label={"Temat"}
                                                isRequired />
                                        </Grid>
                                        <Grid item md={12} className="w-full">
                                            <TextInputField
                                                name={typedNameV2<any>('content')}
                                                label={"Treść"}
                                                isRequired />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12}>
                                    {isLoading ? <CircularProgress /> : (<Button variant={ButtonVariants.PRIMARY} onClick={form.handleSubmit(handleSubmit)}>
                                        <Text variant="p" color="bright" isBold>
                                            Wyślij
                                        </Text>
                                    </Button>)}
                                </Grid>
                            </Grid>
                        </FormV2Context.Provider>
                    </Paper>
                </div></div>
        );
    } else if (variant === BasicPaperVariants.EVENTS) {
        return (
            <div className={isHighlighted ? 'highlighted' : 'none'} data-aos="fade-up">
                <Paper elevation={3} className="basic-paper">
                    <Grid container spacing={3} className="align-items-end">
                        <Grid item md={12}>
                            <Text variant="h4" color="dark" isBold>
                                "{title}"
                            </Text>
                        </Grid>
                        <Grid item md={12}>
                            <Text variant="p" color="dark">
                                Lokalizacja: <br />
                                <strong>{localization}</strong>
                            </Text>
                            <Text variant="p" color="dark">
                                Data rozpoczęcia: <br />
                                <strong>{convertDateToDateTimeFormat(starDate)}</strong>
                            </Text>
                            <Text variant="p" color="dark">
                                Data zakończenia: <br />
                                <strong>{convertDateToDateTimeFormat(finishDate)}</strong>
                            </Text>
                        </Grid>
                        <Grid item md={12}>
                            <Button variant={buttonVariant} onClick={onClick}>
                                <Text variant="p" color="bright" isBold>
                                    Szczegóły
                                </Text>
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    } else if (variant === BasicPaperVariants.EVENT) {
        console.log('schedule: ', schedule);
        console.log('imgSrc: ', imgSrc);
        return (
            <div data-aos="fade-up" className="w-full">
                <Grid container spacing={3}>
                    <Grid item lg={3}>
                        <img src={imgSrc[0].url} alt="Główne zdjęcie wyjazdu" className="w-full" />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <Text variant="h4" color="dark" isBold>
                            Dane podstawowe
                        </Text><br />
                        <Paper className="basic-paper">
                            <Text variant="p" color="dark">
                                Lokalizacja: <br />
                                <strong>{localization}</strong>
                            </Text>
                            <Text variant="p" color="dark">
                                Data rozpoczęcia: <br />
                                <strong>{convertDateToDateTimeFormat(starDate)}</strong>
                            </Text>
                            <Text variant="p" color="dark">
                                Data zakończenia: <br />
                                <strong>{convertDateToDateTimeFormat(finishDate)}</strong>
                            </Text>
                            {price !== 0 && (<Text variant="p" color="dark">
                                Cena: <br />
                                <strong>{price} zł</strong>
                            </Text>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item lg={5} xs={12}>
                        <Text variant="h4" color="dark" isBold>
                            Dodatkowe informacje
                        </Text><br />
                        <Paper className="basic-paper">
                            <Text variant="p" color="dark">
                                <div dangerouslySetInnerHTML={ {__html: description as string} } />
                            </Text>
                        </Paper>
                    </Grid>
                    <Grid item md={12}>
                        <Text variant="h4" color="dark" isBold>
                            Plan zajęć
                        </Text>
                    </Grid>
                    {schedule?.map((element: any) => {
                        return (
                            <Grid item md={12 / schedule.length}>
                                <Paper className="basic-paper">
                                    <Text variant="p" color="dark" isBold>
                                        {element.title}
                                    </Text>
                                    <Text variant="p" color="dark">
                                        <div dangerouslySetInnerHTML={ {__html: element.content as string} } />
                                    </Text>
                                </Paper>
                            </Grid>
                        )
                    })}
                    <Grid item md={12}>
                        <Text variant="h4" color="dark" isBold>
                            Galeria zdjęć
                        </Text>
                    </Grid>
                    <Grid item md={12}>
                        <ImageList variant="masonry" cols={3} gap={8} sx={{ display: { lg: 'block', xl: 'block', md: 'block', sm: 'none', xs: 'none' } }}>
                            {imgSrc.map((item: any) => (
                                <ImageListItem key={item.url}>
                                    <img
                                        src={`${item.url}?w=248&fit=crop&auto=format`}
                                        srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                        alt={item.title}
                                        loading="lazy" 
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                        <ImageList variant="masonry" cols={1} gap={8} sx={{ display: { lg: 'none', xl: 'none', md: 'none', sm: 'block', xs: 'block' } }}>
                            {imgSrc.map((item: any) => (
                                <ImageListItem key={item.url}>
                                    <img
                                        src={`${item.url}?w=248&fit=crop&auto=format`}
                                        srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                        alt={item.title}
                                        loading="lazy" 
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Grid>
                </Grid>
            </div>
        );
    }  else if (variant === BasicPaperVariants.FILMS) {
        return (
            <div className={isHighlighted ? 'highlighted' : 'none'} data-aos="fade-up">
                <Paper elevation={3} className="basic-paper">
                    <Grid container spacing={3} className="align-items-end">
                        <Grid item xs={12}>
                            <div className="responsive-iframe">
                                <iframe 
                                    width="100%" 
                                    height="100%" 
                                    src={filmUrl}
                                    title={title}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    loading="lazy"
                                ></iframe>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Text variant="p" color="dark">
                                "{title}"
                            </Text>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    } else {
        return null
    }; 
};

export default BasicPaper;