import { useMemo } from "react";
import { Page } from "../../modules/common/components";
import { Link } from "react-router-dom";
import { Materials } from "../main/sections";

function MaterialsPage() {
    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                &nbsp;/&nbsp;Materiały
            </>
        )
    }, []);

    return (
        <Page title={title}>
            <Materials isMainPage />
        </Page>
    );
}

export default MaterialsPage;