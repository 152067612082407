import EventsPage from './EventsPage';

const EventsConfig = {
  routes: [
    {
      path: '/warsztat/:id',
      exact: true,
      component: EventsPage
    }
  ]
};

export default EventsConfig;
