import { Button as ButtonComponent } from "@mui/material";
import clsx from 'clsx';
import './Button.css';

interface IProps {
    variant?: 'primary' | 'secondary' | 'purple' | any,
    isOutline?: boolean;
    isFullWidth?: boolean;
    children: React.ReactNode;
    isDisabled?: boolean;
    onClick?: () => void;
}

export enum ButtonVariants {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    PURPLE = 'purple',
    BLUE = 'blue',
    PINKNGREEN = 'pinkngreen',
    YELLOW = 'yellow',
    ORANGE = 'orange'
}

function Button({variant, isOutline, isFullWidth, children, isDisabled, onClick}: IProps) {
    var style = null;

    if (variant === ButtonVariants.PRIMARY) {
        style = ButtonVariants.PRIMARY;
    } else if (variant === ButtonVariants.SECONDARY) {
        style = ButtonVariants.SECONDARY;
    } else if (variant === ButtonVariants.PURPLE) {
        style = ButtonVariants.PURPLE;
    } else if (variant === ButtonVariants.BLUE) {
        style = ButtonVariants.BLUE;
    } else if (variant === ButtonVariants.PINKNGREEN) {
        style = ButtonVariants.PINKNGREEN;
    } else if (variant === ButtonVariants.YELLOW) {
        style = ButtonVariants.YELLOW;
    } else if (variant === ButtonVariants.ORANGE) {
        style = ButtonVariants.ORANGE;
    }

    const outline = isOutline ? 'outline' : null; 
    const fullWidth = isFullWidth ? 'full-width-button' : null;

    return <ButtonComponent onClick={onClick} className={clsx(style, outline, fullWidth)} disabled={isDisabled}>{children}</ButtonComponent>;
}

export default Button;