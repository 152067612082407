import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import type { Engine } from "tsparticles-engine";

const ParticleBackground = () => {
    const particlesInit = useCallback(async (engine: Engine) => {
        await loadFull(engine);
      }, []);

        return (
            <Particles
                id="tsparticles"
                init={particlesInit}
                options={{
                    background: {
                        color: {
                            value: 'transparent',
                        },
                    },
                    fpsLimit: 144,
                    particles: {
                        color: {
                            value: "#ffffff",
                        },
                        collisions: {
                            enable: true,
                        },
                        move: {
                            direction: "right",
                            enable: true,
                            outModes: {
                            default: "out",
                            },
                            random: false,
                            speed: 0.5,
                            straight: false,
                        },
                        number: {
                            density: {
                            enable: true,
                            area: 1080,
                            },
                            value: 100,
                        },
                        opacity: {
                            animation: {
                                enable: true,
                                minimumValue: 0.05,
                                speed: 1,
                                sync: false
                            }
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            random: {
                                enable: true,
                                minimumValue: 0.5
                            }
                        }
                    }
                }}
            />
        )
}

export default ParticleBackground;