import PricelistPage from './PricelistPage';

const PricelistConfig = {
  routes: [
    {
      path: '/cennik',
      component: PricelistPage
    }
  ]
};

export default PricelistConfig;
