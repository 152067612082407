import { useEffect, useMemo, useState } from 'react';
import { AppContext } from './modules/common/context';
import './App.css';
import routes from './configs/routes.config';
import { Router } from 'react-router-dom';
import history from './@history';
import { MainLayout } from './layout';
import AOS from 'aos';
import "aos/dist/aos.css";
import { PuffLoader } from 'react-spinners';
import Text from './modules/common/components/Text/Text';

function App() {
  const routesMemoized = useMemo(() => ({ routes }), []);
  const [isLoading, setLoading] = useState(true);
 
  useEffect(() => {
    AOS.init();
    setTimeout(() => {
      setLoading(false);
    }, 1)
  }, []);
  
  return (
    <div className="App">
      {isLoading ? (
        <div className="h-full w-full d-flex align-items-center justify-content-center flex-column loader">
        <PuffLoader
          color="white"
          loading={isLoading}
          size={150}
        />
        <span className="absolute">
          <Text variant="p" color="bright">Ładowanie...</Text>
        </span>
      </div>
      ) : (
      <AppContext.Provider value={routesMemoized}>
        <Router history={history}>
          <MainLayout />
        </Router>
      </AppContext.Provider>
      )}
    </div>
  );
}

export default App;
