import { Grid } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Page, Text } from "../../../modules/common/components";

function AkashaPage() {
    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                <Link to="/oferta" className="white-font">
                &nbsp;/&nbsp;Oferta
                </Link>
                &nbsp;/&nbsp;Czytanie z Kronik Akaszy
            </>
        )
    }, []);

    return (
        <Page title={title}>
            <Grid container spacing={3}>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Co oznacza słowo Akasza?
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Akasza z tłumaczenia starożytnego tybetu lub słów indyjskich „niewidoczne i nieodczuwalne dla naszych 5 zmysłów miejsce, ukryte miejsce do przechowywania”.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Jaki jest dostęp do Kronik Akaszy?
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Całkowity – w intencji najwyższego dobra. Szósty zmysł, który każdy w sobie posiada – lecz nie w każdym jest świadomość posiadania i chęć do rozwijania tego. Dostępu do Kronik „pilnują” Mistrzowie, Nauczyciele, Przewodnicy oraz Umiłowane Istoty – inaczej mówiąc nasi zmarli przodkowie. Kroniki możemy czytać sami lub przez konsultacje z innymi osobami, które są już dopuszczone do przekazywania wiedzy dla innych. Wtedy Kroniki Akaszy otwierane są poprzez przeczytanie tzw. Świętej Modlitwy. Informacje są wzmocnione z pola miłości, a więc niosą ukojenie, spokój, siłę i nadzieję oraz motywację do dalszego życia.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            O co można pytać?
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Pytać można o wszystko. Odpowiedzi idą bardzo adekwatnie do naszego poziomu świadomości i gotowości wewnętrznej na przyjęcie prawdy. A prawda jest jedna: Dusza ludzka jest nieśmiertelna.
                        </Text>
                    </div>
                </Grid>
            </Grid>
        </Page>
    );
}

export default AkashaPage;