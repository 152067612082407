import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Page } from "../../modules/common/components";

function ContactPage() {
    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                &nbsp;/&nbsp;Kontakt
            </>
        )
    }, []);
    
    return (
        <Page title={title} isNoMargin />
    )
}

export default ContactPage;