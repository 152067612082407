import LawsOfTheUniversePage from './LawsOfTheUniversePage';

const LawsOfTheUniverseConfig = {
  routes: [
    {
      path: '/materialy/7-praw-wszechswiata',
      component: LawsOfTheUniversePage
    }
  ]
};

export default LawsOfTheUniverseConfig;
