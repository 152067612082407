import AboutPage from './AboutPage';

const AboutConfig = {
  routes: [
    {
      path: '/o-nas',
      component: AboutPage
    }
  ]
};

export default AboutConfig;
