import { Redirect } from 'react-router-dom';
import { generateRoutesFromConfigs } from '../modules/common/utils';

import pagesConfig from '../pages/pages.config';

const routeConfigs = [...pagesConfig];

function DefaultRedirect() {
  return <Redirect to={'/strona-glowna'} />;
}

const routes = [
  ...generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    component: () => <DefaultRedirect />
  }
];

export default routes;
