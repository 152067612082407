import { Grid } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Page, Text } from "../../../modules/common/components";

function AkashaPage() {
    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                <Link to="/oferta" className="white-font">
                &nbsp;/&nbsp;Oferta
                </Link>
                &nbsp;/&nbsp;Ustawienia systemowe
            </>
        )
    }, []);

    return (
        <Page title={title}>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Ustawienia systemowe
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <ul>
                                Aby pojąć istotę całego schematu w jakim się poruszamy nieświadomie, pozwolę sobie porównać „egregor” systemowy do chmury, która nad nami wisi i rzuca cień nieustannie powodując, że w nas samych pobudza się chęć odwzajemnienia nastroju chmury. Tak więc – takich chmur mamy wiele od najbliższych naszemu sercu:
                                <li>egregor rodowy,</li>
                                <li>egregor zawodowy,</li>
                                <li>egregor religijny,</li>
                                <li>egregor polityczny,</li>
                                <li>hobby (np. filatelistyczny).</li>
                            </ul>
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <ul>
                                Łatwo się domyśleć, że na każdym etapie naszego życia taki „egregor” nas wspiera, albo też może na nas działać autodestrukcyjnie i wtedy trzeba zadziałać energetycznie, aby wyjść z pogrążającego schematu. Od tego mamy właśnie między innymi narzędzie w postaci tak zwanych USTAWIEŃ  SYSTEMOWYCH. Jest to zespół technik, między innymi:
                                <li>ustawienie na lini życia,</li>
                                <li>ustawienie mama – tata – dziecko,</li>
                                <li>ustawienie na biznes,</li>
                                <li>ustawienie relacji w związku,</li>
                                <li>wgląd w sprawy z punktu widzenia osób trzecich,</li>
                                <li>wiele innych konfiguracji zależnych od celu jaki chcesz osiągnąć. </li>
                            </ul>
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Ustawienie systemowe na planie energetycznym rodu pokazuje schematy, według których jesteśmy trzymani w swoistym „szachu”. Egregor rodowy nie jest „zainteresowany” życiem jednostki (ciebie) tylko średnią wynikową energii zebranych w egregorze i doprowadzeniu do równowagi energetycznej – bywa, że kosztem któregoś z członków rodu. Prawidłowe rozpoznanie tego schematu daje wgląd, a w następstwie przejawia się w świecie fizycznym, jako złamanie blokady.
                        </Text>
                        <Text variant="p" color="dark">
                            Ustawienia systemowe są skierowane do wszystkich osób, które mają w sobie pytanie: dlaczego znów spada to na mnie? Dlaczego jestem obwiniany, choć nie czuję się winny? Dlaczego czuję się winny choć inni mówią mi: to nie twoja wina. Dla wszystkich, którzy nie otrzymali odpowiedzi w samym sobie…. niech przemówi ród.
                        </Text>
                        <Text variant="p" color="dark">
                            Sesje są prowadzone zdalnie oraz stacjonarnie, indywidualnie oraz grupowo, w zależności od potrzeby danego klienta. 
                        </Text>
                    </div>
                </Grid>
            </Grid>
        </Page>
    )
}

export default AkashaPage;