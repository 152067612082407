import { Divider, Grid } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Page, Text } from "../../../modules/common/components";

function LawsOfTheUniversePage() {
    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                <Link to="/materialy" className="white-font">
                &nbsp;/&nbsp;Materiały
                </Link>
                &nbsp;/&nbsp;7 Praw Wszechświata
            </>
        )
    }, []);

    return (
        <Page title={title}>
            <Grid container spacing={3}>
                <Grid item md={6}>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Bez względu na to, czy je znamy, czy nie, żyjemy wg. tych zasad...
                        </Text>
                        <Text variant="p" color="dark">
                            Kybalion – hermetyczny traktat z 1908 roku, autorstwa anonimowych osób, podpisujących się jako Trzej Wtajemniczeni. Kybalion przedstawia filozofię hermetyzmu i siedem praw, na których się ona opiera:
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} />
                <Grid item md={12}>
                    <Divider />
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            1. Prawo mentalizmu (umysłowości):
                        </Text>
                        <Text variant="p" color="dark">
                            Wszystko jest umysłem; Wszechświat jest Mentalny; Wszechświat składa się z myśli.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            2. Prawo powiązania:
                        </Text>
                        <Text variant="p" color="dark">
                            Jak na górze, tak i na dole; Jak na dole, tak i na górze.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            3. Prawo wibracji:
                        </Text>
                        <Text variant="p" color="dark">
                            Nic nie spoczywa; Wszystko się porusza; Wszystko jest w ruchu; wszystko wibruje.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            4. Prawo biegunowości (dwoistości):
                        </Text>
                        <Text variant="p" color="dark">
                            Wszystko jest dwoiste (podwójne); wszystko ma swoje bieguny; wszystko ma swoje pary przeciwieństw; to i przeciwne do niego są tym samym (podobne i niepodobne są tym samym); przeciwieństwa mają identyczną naturę (są identyczne w swojej naturze), różnica leży w natężeniu; skrajności się spotykają; wszystkie prawdy są jedynie półprawdami; wszystkie paradoksy da się pogodzić.”
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            5. Prawo rytmu:
                        </Text>
                        <Text variant="p" color="dark">
                            „Wszystko wpływa i wypływa; Wszystko płynie, na zewnątrz i do środka; wszystko ma swoje pływy (fale); wszystko wznosi się i opada (wszystkie rzeczy wznoszą się i upadają); ruch wahadła manifestuje (przejawia) się we wszystkim; odchylenie w lewo jest równe odchyleniu w prawo (miara wychylenia w prawo jest miarą wychylenia w lewo); rytm dąży do wyrównania (rytm się kompensuje).”
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            6. Prawo przyczyny i skutku:
                        </Text>
                        <Text variant="p" color="dark">
                            Każda przyczyna ma swój skutek, a każdy skutek ma swoją przyczynę; wszystko dzieje się według Prawa; przypadek nie jest niczym innym, jak inną nazwą Prawa, gdy ono nie jest rozpoznane (przypadek to jedynie nazwa dla nierozpoznanego Prawa); istnieje wiele poziomów, na których rozgrywają się przyczyny, lecz nic nie ucieknie przed Prawem (istnieje wiele płaszczyzn przyczynowości, lecz nic nie wymyka się Prawu).
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            7. Prawo rodzaju (płci):
                        </Text>
                        <Text variant="p" color="dark">
                            Rodzaj (płeć) jest we wszystkim; wszystko ma swoje męskie i kobiece pierwiastki (aspekty); rodzaj (płeć) przejawia się (manifestuje) na wszystkich poziomach (płaszczyznach).
                            <br />
                            ~ WIEMY TEŻ O PRAWIE PRZYCIĄGANIA – dlaczego nie jest wymienione jako, np. 8 prawo? Dlatego, że nie istnieje jako samodzielne, a przejawia się we wszystkich siedmiu.
                        </Text>
                    </div>
                </Grid>
            </Grid>
        </Page>
    )
}

export default LawsOfTheUniversePage;