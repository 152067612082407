import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { Page, Text } from "../../../modules/common/components";
import { ButtonVariants } from "../../../modules/common/components/Button/Button";
import { useEventQuery } from "../../../modules/workshops/api/queries/useEventQuery";
import { BasicPaper } from "../../main/components";
import { BasicPaperVariants } from "../../main/components/BasicPaper";

function EventsDetailsPage() {
    const params = useParams();
    const { data } = useEventQuery(params);
    
    const renderWorkshops = () => {
        return !data ? (
            <Grid item md={12}>
                <Text variant="p" color="dark">
                    Brak dostępnego opisu :(
                </Text>
            </Grid>
        ) : (
            <Grid item md={12}>
                <BasicPaper
                    variant={BasicPaperVariants.EVENT}
                    buttonVariant={ButtonVariants.PRIMARY}
                    title={data.title}
                    description={data.description}
                    localization={`${data.address} ${data.city} ${data.postcode}, ${data.country}`}
                    starDate={data.startDate}
                    finishDate={data.finishDate}
                    price={data.price}
                    schedule={data.schedule}
                    imgSrc={data.multimedia}
                />
            </Grid>
        )
    };

    return (
        <Page title={`${data?.title}`} isEventPage>
            <Grid container spacing={3}>
                {renderWorkshops()}
            </Grid>
        </Page>
    ) 
}

export default EventsDetailsPage;