import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Page, Text } from "../../modules/common/components";

function createData(
    nazwa: string,
    kronikiAkaszy: string,
    ustawieniaSystemowe: string,
    regresja: string,
    bioenergotarapia: string,
    radiestezja: string
    ) {
    return { nazwa, kronikiAkaszy, ustawieniaSystemowe, regresja, bioenergotarapia, radiestezja };
}

function PricelistPage() {
    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                &nbsp;/&nbsp;Cennik
            </>
        )
    }, []);

    const rows = [
        createData('Cena', '500 zł', '500 zł', '500 zł', '300 zł', '300 zł'),
        createData('Czas', '2 godziny', 'do 3 godzin', 'do 2 godzin', '-', '-')
    ];

    return (
        <Page title={title}>
            <Grid container spacing={3}>
                <Grid item md={6}>
                    <div data-aos="fade-up">
                        <Box sx={{ overflow: "auto" }}>
                            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell align="left"><strong>Odczyt&nbsp;z Kronik&nbsp;Akaszy</strong></TableCell>
                                            <TableCell align="left"><strong>Ustawienie systemowe</strong></TableCell>
                                            <TableCell align="left"><strong>Regresja hipnotyczna</strong></TableCell>
                                            <TableCell align="left"><strong>Skanowanie, oczyszczanie, bioenergoterapia</strong></TableCell>
                                            <TableCell align="left"><strong>Oczyszczanie, synchronizacja, radiestezja</strong></TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {rows.map((row) => (
                                            <TableRow
                                                key={row.nazwa}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                            <TableCell component="th" scope="row">
                                                <strong>{row.nazwa}</strong>
                                            </TableCell>
                                            <TableCell align="left">{row.kronikiAkaszy}</TableCell>
                                            <TableCell align="left">{row.ustawieniaSystemowe}</TableCell>
                                            <TableCell align="left">{row.regresja}</TableCell>
                                            <TableCell align="left">{row.bioenergotarapia}</TableCell>
                                            <TableCell align="left">{row.radiestezja}</TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            *Cennik ma charakter poglądowy. Dokładne ustalenie ceny, następuje w momencie uzgodnienia terminu, czasu trwania oraz zakresu działań - dla każdej sesji indywidualnie.
                        </Text>
                    </div>
                </Grid>
            </Grid>
        </Page>
    );
}

export default PricelistPage;