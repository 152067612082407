import { Grid } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Page, Text } from "../../../modules/common/components";

function RegressionPage() {
    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                <Link to="/oferta" className="white-font">
                &nbsp;/&nbsp;Oferta
                </Link>
                &nbsp;/&nbsp;Regresja hipnotyczna
            </>
        )
    }, []);

    return (
        <Page title={title}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Regresja hipnotyczna
                        </Text>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Jedna z naturalnych technik terapeutycznych, która ma na celu dotarcie do podświadomości, gdzie zapisana jest trauma związana z losowym zdarzeniem. W przypadku traumatycznego zdarzenia losowego, można wyróżnić dwa sposoby zapisu informacji, które mogą utrudniać funkcjonowanie na różnych poziomach, takich jak zdrowie, praca, związki itp. Pierwszym sposobem jest intensywność, czyli krótkotrwałość zdarzenia, trwającego np. kilka, kilkanaście lub kilkadziesiąt minut. Drugim sposobem jest przewlekłość, czyli długotrwałość zdarzenia, trwającego powyżej około 40 dni. Oba sposoby zapisu funkcjonują w podświadomości i tworzą schemat traumy.
                        </Text>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Gdy mózg odbiera traumatyczne zdarzenie, logika podpowiada aby odlożyć problem na później, a cała sytuacja zostaje przeniesiona do podświadomości i tam czeka na uruchomienie. Czynniki zewnętrzne, takie jak pora roku, data, pogoda, kolor, muzyka, konkretne zdanie lub słowo, mogą uruchomić proces, w którym podświadomość chce ujawnić schemat traumy.
                        </Text>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            W mojej praktyce spotkałam klienta, który tracił przytomność w różnych miejscach i okolicznościach, bez widocznej przyczyny. Okazało się, że traci przytomność zawsze w momencie usłyszenia konkretnego słowa, które brzmiało w nim od 8 roku życia i wyłączało funkcje aktywności ciała.
                        </Text>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Regresja polega na cofaniu się i naprawieniu śladu pamięciowego w podświadomości. Hipnoza, jako stan umysłu, występuje codziennie u każdego z nas w postaci snu, kiedy to mózg przechodzi w sposób naturalny w stan Alfa lub Theta. Hipnoza regresyjna pozwala na wprowadzenie mózgu na pożądane fale w sposób zamierzony.
                        </Text>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Ilość sesji potrzebnych, aby dotrzeć do zapisanej traumy oraz wprowadzić umysł na wystarczająco głęboki zakres fal mózgowych, zależy od zaufania, jakim mnie obdarzysz. Każda sesja, nawet ta niezbyt głęboka, pokaże Ci to, na co jesteś gotowy(a) "oddać", powierzyć mi, tak jak nigdy nikomu nie powierzyłeś(łaś). Na głębokich płaszczyznach podświadomości przechowywane są dane ze świata duchowego oraz wcześniejszych inkarnacji, a każda sesja jest poprzedzona rozmową oraz ustaleniem celu.
                        </Text>
                    </div>
                </Grid>
            </Grid>
        </Page>
    )
}

export default RegressionPage;