import { QueryConfig, useQuery } from 'react-query';
import API from '../../../../api/endpoints';

const WORKSHOP_LIST = 'WORKSHOP_LIST';

const getWorkshopList = async () => {
  const { data } = await API.getWorkshopList();

  return data?.rows;
};

export const useWorkshopsQuery = (queryConfig: QueryConfig<string, unknown> = {}) => {
  return useQuery(WORKSHOP_LIST, getWorkshopList, queryConfig);
};
