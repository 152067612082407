import DowsingPage from './DowsingPage';

const DowsingConfig = {
//   settings: {
//     layout: {
//       config: {
//         navbar: {
//           display: false
//         },
//         topBar: {
//           display: false
//         },
//         footer: {
//           display: false
//         }
//       }
//     }
//   },
  routes: [
    {
      path: '/oferta/radiestezja',
      component: DowsingPage
    }
  ]
};

export default DowsingConfig;
