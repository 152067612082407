import { Grid } from "@mui/material";

interface IProps {
    isDark?: boolean;
}

function Socialmedia({isDark}: IProps) {
    return (
        isDark ? (
            <Grid container spacing={1}>
                <Grid item>
                    <a href="https://pl.pinterest.com/kronikiakaszy/">
                        <img src="/assets/images/pinterest-dark.svg" alt="Odnośnik do social media - pinterest" />
                    </a>
                </Grid>
                <Grid item>
                    <a href="https://www.tiktok.com/@kroniki_akaszy">
                        <img src="/assets/images/tiktok-dark.svg" alt="Odnośnik do social media - tiktok" />
                    </a>
                </Grid>
                <Grid item>
                    <a href="https://www.youtube.com/channel/UCvxMtrBMz4qt_FeQpGlk7_g">
                        <img src="/assets/images/youtube-dark.svg" alt="Odnośnik do social media - youtube" />
                    </a>
                </Grid>
                <Grid item>
                    <a href="https://www.facebook.com/profile.php?id=100076264266960">
                        <img src="/assets/images/facebook-dark.svg" alt="Odnośnik do social media - facebook" />
                    </a>
                </Grid>
            </Grid>
        ) : (
            <Grid container spacing={1}>
                <Grid item>
                    <a href="https://pl.pinterest.com/kronikiakaszy/">
                        <img src="/assets/images/pinterest-bright.svg" alt="Odnośnik do social media - pinterest" />
                    </a>
                </Grid>
                <Grid item>
                    <a href="https://www.tiktok.com/@kroniki_akaszy">
                        <img src="/assets/images/tiktok-bright.svg" alt="Odnośnik do social media - tiktok" />
                    </a>
                </Grid>
                <Grid item>
                    <a href="https://www.youtube.com/channel/UCvxMtrBMz4qt_FeQpGlk7_g">
                        <img src="/assets/images/youtube-bright.svg" alt="Odnośnik do social media - youtube" />
                    </a>
                </Grid>
                <Grid item>
                    <a href="https://www.facebook.com/profile.php?id=100076264266960">
                        <img src="/assets/images/facebook-bright.svg" alt="Odnośnik do social media - facebook" />
                    </a>
                </Grid>
            </Grid>
        )
    );
}

export default Socialmedia;