import React from 'react';

import Loading from './Loading/Loading';

interface SuspenseProps {
  loadingProps: object;
  children: React.ReactNode;
}

function Suspense({ loadingProps, children }: SuspenseProps) {
  return <React.Suspense fallback={<Loading {...loadingProps} />}>{children}</React.Suspense>;
}

Suspense.defaultProps = {
  loadingProps: {
    delay: 0
  }
};

export default Suspense;
