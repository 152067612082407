import React, { Context, useContext } from 'react';
import { Controller, FieldPath, FieldValues } from 'react-hook-form';
import clsx from 'clsx';
import { InputBaseProps } from '@mui/material';
import FormV2Context from '../formsV2/context/FormV2Context';
import { InputMode } from '../model/input.model';
import TextInput from '../components/input/TextInput';

interface IProps {
  name: FieldPath<FieldValues>;
  lines?: number;
  label?: string;
  formContext?: Context<any>;
  className?: string;
  valueClassName?: string;
  isOnlyPositiveIntegers?: boolean;
  isOnLimitDemical?: boolean;
  getValueFormat?: (value: any) => any;
  inputProps?: InputBaseProps['inputProps'];
  inputMode?: InputMode;
  placeholder?: string;
  type?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  isHidden?: boolean;
  isLabelHidden?: boolean;
  onInput?: (e: any) => void;
  validation?: any;
}

enum FormErrorType {
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}

function TextInputField({
  name,
  lines,
  formContext = FormV2Context,
  className,
  valueClassName,
  label,
  isOnlyPositiveIntegers,
  isOnLimitDemical,
  isDisabled,
  placeholder,
  isRequired,
  inputProps,
  isHidden,
  isLabelHidden,
  inputMode,
  getValueFormat,
  validation,
  ...forwardProps
}: IProps) {
  const { control } = useContext(formContext);

  const onPositiveNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/\D/g, '').trim();
  };

  const onLimitDemicalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    const dotPosition = numberValue.indexOf('.');
    e.target.value =
      dotPosition >= 0 ? numberValue.substr(0, dotPosition) + numberValue.substr(dotPosition, 3) : numberValue;
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur }, fieldState: { error } }) =>
          <TextInput
            name={name}
            label={label}
            className={clsx(className, isHidden && 'hidden')}
            value={value || ''}
            onChange={onChange}
            {...(isOnlyPositiveIntegers ? { onInput: onPositiveNumberChange } : {})}
            {...(isOnLimitDemical ? { onInput: onLimitDemicalChange } : {})}
            onBlur={onBlur}
            required={isRequired || Boolean(validation?.required) || false}
            isLabelHidden={isLabelHidden || false}
            error={!!error && error?.type !== FormErrorType.WARNING}
            isWarning={error?.type !== FormErrorType.WARNING}
            helperText={error?.message}
            disabled={isDisabled}
            placeholder={placeholder}
            {...(lines ? { rows: lines, multiline: true } : {})}
            inputProps={inputProps}
            fullWidth
            {...forwardProps}
          />
      }
    />
  );
}

export default TextInputField;
