import { useHistory } from "react-router-dom";
import { ButtonVariants } from "../../../modules/common/components/Button/Button";
import { BasicPaper } from "../components";
import { BasicPaperVariants } from "../components/BasicPaper";
import Text from '../../../modules/common/components/Text/Text';
import { useWorkshopsQuery } from "../../../modules/workshops/api/queries/useWorkshopsQuery";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

function Workshops() {
    const history = useHistory();
    const body = document.querySelector('#root');
    const { isLoading, data } = useWorkshopsQuery();
    
    const navigateTo = (page: string) => {
        body?.scrollIntoView();
        history.push(page)
    }

    const renderWorkshops = () => {
        return data ?? !isLoading ? (
            data.map((workshop: any) => {
                return (
                    <SwiperSlide>
                            <BasicPaper
                                imgSrc={workshop?.imageUrl}
                                imgAlt="Oferta Czytania z Kronik Akaszy"
                                variant={BasicPaperVariants.WORKSHOPS}
                                buttonVariant={ButtonVariants.PRIMARY}
                                title={workshop?.title}
                                description={workshop?.description}
                                onClick={() => navigateTo(`/warsztat/${workshop?.id}`)}
                                isHighlighted={workshop?.isHighlighted}
                            />
                    </SwiperSlide>
                )
            })
        ) : null;
    };

    return (
        <section className="workshops container" id="warsztaty">
            <div data-aos="fade-up">
                <Text variant="h2" isBold color="dark">
                    Warsztaty
                </Text>
            </div>
            <Swiper
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 50,
                    },
                    1000: {
                        slidesPerView: 2,
                        spaceBetween: 50,
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    }
                }}
                modules={[Navigation]}
                className="pt-10"
                grabCursor
                navigation
                loop
            >
                {renderWorkshops()}
            </Swiper>
        </section>
    );
}

export default Workshops;