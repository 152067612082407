import { LinearProgress, Typography } from '@mui/material';
import React, { useState } from 'react';

import useTimeout from '../../hooks/useTimeout';

interface LoadingProps {
  delay: Array<number | boolean>;
}

function Loading({ delay }: LoadingProps) {
  const [showLoading, setShowLoading] = useState(!delay);

  useTimeout(() => {
    setShowLoading(true);
    //@ts-ignore
  }, delay);

  if (!showLoading) {
    return null;
  }

  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <Typography className="text-20 mb-20" color="textSecondary">
        "Ładowanie"
      </Typography>
      <LinearProgress className="w-xs" color="primary" />
    </div>
  );
}

Loading.defaultProps = {
  delay: false
};

export default Loading;
