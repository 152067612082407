import ContactPage from './ContactPage';

const ContactConfig = {
  routes: [
    {
      path: '/kontakt',
      component: ContactPage
    }
  ]
};

export default ContactConfig;
