import { Grid } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Page, Text } from "../../../modules/common/components";

function SystemSettingExamplesPage() {
    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                <Link to="/materialy" className="white-font">
                &nbsp;/&nbsp;Materiały
                </Link>
                &nbsp;/&nbsp;Przykładowe ustawienia systemowe
            </>
        )
    }, []);

    return (
        <Page title={title}>
            <Grid container spacing={3}>
                <Grid item md={6}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Związki
                        </Text>
                        <Text variant="p" color="dark">
                            Wizyta mężczyzny.<br /><br />
                            Cel: usłyszeć odpowiedź: czy zakończyć związek i wejść w nową relację… czy „naprawiać zepsuty” a jesli tak, to od czego zacząć?<br /><br />
                            Ustawienie ” na krzesłach ” Otwieramy” pole” Zwykle ustawiam ja lub Marek. Dziś ustawiamy oboje.<br /><br />
                            Klient wg.naszego prowadzenia chodząc po pokoju wybiera sobie miejsce, w którym chce się  intuicyjnie zatrzymać. W tym miejscu ustawiamy mu krzesło z jego energią (wybrał na przeciwko okna). Dalej klient chodząc po pokoju, wybiera sobie miejsca, w którym ustawiamy krzesło z energią jego obecnej partnerki. (Anna) Siada na swoim krześle, chwilę  czekamy, aż „spłynie” energia.<br /><br />
                            Ja pytam: Na co patrzysz i co czujesz? – Patrzy w szybę, tam jest odbicie drugiego krzesła. Za jego plecami.<br /><br />
                            Ustawił swoją energię i energię partnerki w odległości kilku metrów od siebie w taki sposób, że on widzi ją tylko w szybie, a ona – gdyby siedziała – tylko jego plecy.<br /><br />
                            Ja pytam: czy masz ochotę się do niej odwrócić? – odpowiada, że nie.<br /><br />
                            Ich związek jest beznamiętny… on widzi ją tylko wirtualnie, a ona za nim z tyłu, w cieniu, bez możliwości dosięgnięcia i nadążania za jego tempem.<br /><br />
                            Proszę, aby przesiadł się na drugie krzesło w energii swojej partnerki.<br /><br />
                            – Czekamy chwilę, aż energia „spłynie”. Pytam:  co czujesz siedząc w jej energii? – Zeszły na niego emocje, przyszła informacja: jestem sama, jest mi zimno (przeklina) k… jestem całkiem sama (płacze).<br /><br />
                            Nie patrzy na krzesło przed sobą, które jest energią jej partnera. Patrzy w podłogę… to znak, że widzi śmierć kogoś, kto był dla niej ważniejszy niż partner...<br /><br />
                            – Układam w tym miejscu koc… proszę Marka, żeby przejął prowadzenie a sama kładę się w miejscu gdzie mężczyzna kieruje wzrok…”zwijam się w kłębek” i czekamy chwilę, aż „spłynie” energia z informacją. On siedząc na krześle jej energii, już zna jej odczucia, jej ból, żal, tęsknotę. Już wie co ich dzieli… dziecko które utraciła. Nie było jego, było wcześniej, energia uwięziona w przeszłości.<br /><br />
                            Zalegająca  pomiędzy nimi pośrodku… pomiędzy krzesłami, oddzielając ich oboje od siebie. Chwilę trwa, emocje i energia uwolniły się, aż on wie.. aż podniesie wzrok wyżej, ku swojemu krzesłu, aż nastanie wewnętrzny spokój, aż weźmie głębszy oddech, aż wewnętrzny głos powie kocham i ufam. Teraz możesz usiąść z powrotem na swoim krześle… prowadzi Marek...<br /><br />
                            – Ponowne pytanie kierowane do niego. Czy teraz chcesz obrócić się w kierunku energii swojej partnerki?<br /><br />
                            On mówi, że tak – bardzo. Cofa swoje krzesło około półtora metra do tyłu w jej kierunku. Marek prosi, aby przesiadł się w energię partnerki. Teraz i z tego miejsca płynie ruch w kierunku energii męskiej.<br /><br />
                            – Ponowna prośba, aby przesiadł się na swoje krzesło , a on z radością przesuwa je rownając się z nią . Mamy równowagę obu energii (żeńskiej i męskiej). Dziecko zostało „odprowadzone”.<br /><br />
                            Pytanie Marka: czy jest ci jeszcze potrzebne krzesło z energią drugiej kobiety, o której myślałeś?<br /><br />
                            – Jednoznacznie odpowiada, że nie. Już nie potrzebuję wiedzieć, czy ta druga kobieta do mnie coś czuje, bo wiem co ja czuję do swojej partnerki i co Anna do mnie…to mi wystarczy…<br />
                            Zamykamy „pole”.
                        </Text>
                    </div>
                </Grid>
            </Grid>
        </Page>
    )
}

export default SystemSettingExamplesPage;