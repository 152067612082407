import { Grid } from "@mui/material";
import { Text } from "../../components";
import ContactForm from "../../sections/contactForm/ContactForm";
import "./Page.css";

interface IProps {
    title?: any;
    children?: React.ReactNode;
    isContactFormHidden?: boolean;
    isEventPage?: boolean;
    isNoMargin?: boolean;
}

function Page({title, children, isContactFormHidden, isEventPage, isNoMargin}: IProps) {
    return (
        <section className="subpage h-full">
            <div className="subpage-hero container d-flex align-items-end">
                <Grid container spacing={3}>
                    <Grid item>
                        <Text variant="breadcrumbs" color="bright" isBold>{title}</Text>
                    </Grid>
                </Grid>
            </div>
            <div className="subpage-content container py-5">
                {children}
            </div>
            {!isContactFormHidden ? (
                <div className="subpage-contact-form">
                    <ContactForm isEventPage={isEventPage} isNoMargin={isNoMargin}  />
                </div>) : null
            }
        </section>
    )
}

export default Page;