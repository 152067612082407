import { useContext } from 'react';
import { renderRoutes } from 'react-router-config';
import { AppContext } from '../modules/common/context';
import Suspense from '../modules/common/components/Suspense';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';

function MainLayout() {
  const appContext = useContext<{ routes: any[] }>(AppContext);
  const { routes } = appContext;

  return (
    <div>
      <Navbar />
        <Suspense>{renderRoutes(routes)}</Suspense>
      <Footer />
    </div>
  );
}

export default MainLayout;
