import { Divider, Grid } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Page, Text } from "../../../modules/common/components";

function NonPersonalizedReadingPage() {
    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                <Link to="/materialy" className="white-font">
                &nbsp;/&nbsp;Materiały
                </Link>
                &nbsp;/&nbsp;Odczyty niespersonalizowane
            </>
        )
    }, []);

    return (
        <Page title={title}>
            <Grid container spacing={3}>
                <Grid item md={6}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Kroniki Akaszy - Przesłanie na Nowy Rok
                        </Text>
                        <Text variant="p" color="dark">
                            Przekaz jest przekazem, energia energią jest. Na ten czas wszystko jest ważne. Nie jutro, nie wczoraj, nie gdzieś, a teraz… Teraz wszystko tutaj jest. Jesteś istotą ze światłości. Zrodzoną dla miłości, dla ważności, dla roztropności, aby wzrastać w swym czystym mniemaniu, aby pozostać we własnym poważaniu. Bo kto doceni Twoją pracę i Twój znój, jak nie Ty sam Siebie docenisz. Kto dojrzy w Tobie diament, gdy Sam dla Siebie kamieniem jesteś… Pracuj, pracuj a garb sam Ci wyrośnie… tak Ci mówili. Stań zatem w cieniu i popatrz sobie jak tu się czujesz? Stań zatem w świetle i sprawdź, czy ulgę masz? Poczuj wewnętrznie te dwie przestrzenie. Z czym rezonujesz? Która energia Ci odpowiada? Co w Duszy masz? Tym się pokieruj wychodząc z cienia, że to jest gra. Kieruj Swe myśli zawsze w głąb Siebie i zadaj pytanie… czy Sam dla Siebie Swym przyjacielem teraz zostanę? Akceptuj zatem co w Tobie siedzi… wszystkie przywary, kiedy Ci północ zaczną oznajmiać ziemskie zegary. Reset dla Ciebie, reset dla innych istot na świecie, nastąpi wtedy, kiedy do Siebie drogę znajdziecie. Prawda jest w każdym, miłość jest każdy – tak bez wyjątku! Wszyscy jesteście, jedną jednością na ziemskim przylądku. Wszyscy tą drogę do Samych Siebie już przecież znacie… kiedy do innej ziemskiej istoty powiesz: MÓJ BRACIE…
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} />
                <Grid item md={12}>
                    <Divider />
                </Grid>
                <Grid item md={12}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            25.11.2021, godzina 03:03
                        </Text>
                        <Text variant="p" color="dark">
                            <div data-aos="fade-up">
                                pię. Nie śpię. Granica pomiędzy snem a przebudzeniem i głos: „to , dla tych ktorzy są w lęku „… Wstaję, idę w półmroku do innego pokoju… będę pisać… wiem, że mam pisać… światło, papier, długopis… chwilę czekam, unosząc wzrok jakbym czegoś szukała na pograniczu ściany i sufitu… Głos ze snu. Teraz na jawie. Piszę:<br /><br/>
                            </div>
                            <div data-aos="fade-up">
                                <strong>– Przekaz dla wrażliwych istot, które są w lęku:</strong><br /><br/>
                                Certyfikat bezpieczeństwa to jest. Gdy Dusza zmęczona, zwątpiona już jest, a płacze w lęku – ocknij się, Istotą Świetlistą jesteś, a nie tylko człowiekiem ułomnym. Wstań z kolan lęku, a wrażliwość swoją wykorzystaj patrząc wstecz, w inne trudne chwile, które już pokonałeś po wielokroć. Z wdzięcznością „w górę leć i opadaj ” z wdzięcznością, bo ludzkie to jest. Nie doświadczysz tego potem, a teraz. Teraz wszystko twoje jest. Twoje „teraz” ma wielką moc. Kiedy teraz wierzysz – inną istotą, teraz się stajesz:<br /><br/>
                                Ja jestem czystą Mocą, która żyje dniem i nocą. Ja Moc swoją odzyskuje, sam sobie dziękuję. Bo połączony jestem ze Stwórcą, bo Stwórca we mnie jest. Wszystko jest prawdą wokół mnie, bo ja jestem prawdą… to moje jest.<br/><br/>
                                Co z tym zrobić – pytam. Odp. Przekaż niebawem. Znajdzie to ten, kto powinien znaleźć.<br /><br />
                            </div>
                            <div data-aos="fade-up">
                                <strong>– Przekaz dla ubogich:</strong><br /><br />
                                Stąpam po ziemi, a w oknach mrok. Mijam to wszystko, ten trudny rok .Mijam, zostawiam, przestaję myśleć . Piszę ten list… lecz gdzie go wyślę? Może do Boga? Może do siebie? Może do rodu? Tak… to tam kiedyś, dawno przed laty, ktoś umarł z głodu. Z głodu, czy z zimna, z braku miłości… teraz to w moim sercu wrażliwym chce się rozgościć. Ja z zaufaniem to wszystko w sobie dziś rozpoznaję. Z przodkiem swym niosę i rozwiązuję trudne zadanie. To co wyjęte na światło dzienne, nagle ożywa, listki wypuszcza, jeszcze nieśmiało piękna roślina. To co na nowy rok już z pewnością tak oczekuję… to, że w mym sercu ziarno posiane wzejdzie, kiełkuje. Czas już uzdrowić ślady po cierniach, mojego rodu. Swoim wyborem i wolną wolą wychodzę z głodu. Pierwsze co wiem i teraz niosę, to zaufanie, że życie teraz jest już otwarte i właśnie na mnie. To ja jestem zmianą. To ja wybieram i decyduję. Od tu i teraz zdanie wyłącznie takie stosuję: Ufam sobie, Kocham siebie, Sobie Dziękuję. Kiedy do siebie list taki piszę oraz do rodu, to tak jakbym wręczył go tu i teraz samemu Bogu. Bo on jest we mnie, bo on jest w rodzie, w każdej istocie.<br /><br />
                                Od tu i teraz wszystko rozumiem i wszystko znam. Poczułem mocniej co tu się dzieje, co darmo mam. Mam więc powietrze, mam więc tę ziemię i ogień mam… wodę mam także, kiedy deszcz pada i słońce mam. Gdy to doceniam, także wieczorem, gdy idę spać prawo Wszechświata i przyciągania pozwala brać. W wolnym przepływie, gdy radość, miłości i zaufanie mam… odpuszczam sobie wszystkie te role, które wciąż gram. Dopiero wtedy płynie też pieniądz w wolnym przepływie. Sam więc ze sobą wciąż się układam i gram uczciwie.<br /><br />
                                Jestem, Jesteś tu i teraz.<br /><br />
                            </div>
                            <div data-aos="fade-up">
                                <strong>– Przekaz dla gniewnych:</strong><br /><br />
                                Niech wszyscy gniewni stanął tu obok. Bo w moim rodzie wszystko jest. Pozwólcie teraz moi mili, że ja z szeregu wyłamię się. Stanę przed wami, twarzą w twarz, wdzięczność przekażę w oczy wam… zmęczony jestem… wybieram was, tymi, wielkimi, co dali radę. Sobie pozwalam być słabym. Sobie pozwalam być innym. Sobie pozwalam opuścić ręce i iść w świat bez walki, bez gniewu bez oręża. Dziś mam swój świat. W miejsce gniewu wpisuję – strach. Czy taka zamiana się uda? Tak – ona jest cały czas, bo pod gniewem jest strach. Wybieram inną drogę, patrzę na rodu trwogę, wpisuję inny znak… pokora… to mój nowy świat. W tym świecie jestem pogodny i zaufania godny, miłością wypełniony , na tle innych niby szalony a towarzyszy mi moje nowe „Ja” i ród mi ufa i też żyje moim „Ja”… spokojnie… spokojnie.<br /><br />
                            </div>
                            <div data-aos="fade-up">
                                <strong>– Przekaz dla potrzebujących uzdrowienia:</strong><br /><br />
                                Jest taka święta , święta Rita. W sprawach trudnych pamięć o Niej zakwita. Nie miej żadnych wyrzutów sumienia, że Twoja pamięć o Niej się zmienia. To rzeczą naturalną jest, że gdy potrzeba… to pamięć jest. Zwracaj się kiedy więc potrzebujesz. Gdy swoją wdzięczność Jej podarujesz, Ona ci drogę dobrą wskazuje i pośrednikiem doskonałym jest i niesie wdzięczność za uzdrowienie też. Jeśli więc sam nie czujesz się na siłach, aby z Duszą i Stwórcą rozmawiać bezpośrednio, z nią rozmawiaj. Błogosławieni, którzy nie widzieli a uwierzyli. Uwierz i ty. Kiedy twe serce i Dusza są w rozpaczy, oddaj się w pamięć Tej Doskonałej, a pomnij o Niej tylko raz a z zaufaniem. Co z zaufaniem szybko się stanie. Nie Każdy wierzy, że sam się uzdrowi i może. Tam święta Rita zawsze pomoże. Nie tylko zdrowie lecz inne sprawy możesz powierzyć jej bez obawy. Tym co doświadczysz, podziel się z innymi. Czasem dla niektórych, ważne są godziny. Godziny ? Czy tyle wystarczy?<br /><br />
                                Wystarczy krótka chwila. Zaufaj, poczuj i idź w dalszą drogę…<br /><br />
                                „Na teraz to tyle… patrzę na zegarek… za chwilę 6 rano… idę spać… nie… pójdę wypić wodę i ćwiczyć… na spanie to jest zbyt późno.” 
                            </div>
                        </Text>
                    </div>
                </Grid>
            </Grid>
        </Page>
    )
}

export default NonPersonalizedReadingPage;