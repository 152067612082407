import { Divider, Grid } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Page, Text } from "../../../modules/common/components";

function DowsingPage() {
    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                <Link to="/oferta" className="white-font">
                &nbsp;/&nbsp;Oferta
                </Link>
                &nbsp;/&nbsp;Radiestezja
            </>
        )
    }, []);

    return (
        <Page title={title}>
            <Grid container spacing={3}>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Słowami wstępu
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Radiestezja jest obecna w życiu człowieku od 6000 lat przed naszą erą. Obejmowała ona Chiny, Indie, Egipt oraz Amerykę Przedkolumbijską. W Polsce datowanie najstarszego dokumentu o zastosowaniu różdżki to zapis z 1450 roku. W tamtym czasie była ona wykorzystywana do poszukiwania wody, kruszców oraz jak też rzeczy zagubionych. Szeroko badane zjawisko radiestezji terapeutycznej zajęło kilkadziesiąt lat francuskiego radiestety Andre Bovis’a (1871-1947, pracującego w szpitalu). To według jego skali jest badany poziom energetyczny ludzi, zwierząt, miejsc oraz żywności, itp. Obecnie jest wiele skal mierniczych używanych przez radiestetów, dający wynik (diagnozę) oraz metodykę uleczenia.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Dla kogo radiestezja terapeutyczna?
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Dla wszystkich istot żywych, a szczególnie dla człowieka. Bardzo szerokie spektrum diagnozowania i działania pozwala na przeprowadzenie uniwersalnej energii przez organizm, celem oczyszczenia i synchronizacji oraz ustawienia prawidłowej polaryzacji w ciele energetycznym, a w efekcie dotarcie do ciała fizycznego. Obszarem działania radiestezji terapeutycznej są też ciała subtelne człowieka. Od radiestety wymaga się szczególnej koncentracji oraz przestrzegania BHP pracy i rzetelnej wiedzy w temacie. Pierwszym sygnałem dla człowieka, kiedy radiestezja może być pomocna jest zmęczenie, napięcie ciała fizycznego, mimo pozornie dobrego stanu zdrowia. To zmęczenie pojawia się na skutek zachwiania prawidłowego krążenia energii. Energii krążącej oraz przenikającej wszystko i wszystkich.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Przyczyny zakłóceń
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Przyczynami zakłóceń w tych przepływach mogą być: smog energomagnetyczny, energia niekorzystnych kształtów, smog emocjonalny i mentalny (z umysłu), itp. Tym wszystkim emanuje istota żywa i to wszystko jest „przesiewane” przez inne istoty. W efekcie jesteśmy średnią wynikową dużej całości masy energii. Zmieniając więc swoje myślokształty i emocje na pozytywne, kształtujemy swoją i nieswoją przestrzeń na pozytywną, ponieważ to od myśli zaczyna się wszystko.
                        </Text>
                    </div>
                </Grid>
                <Grid item className="w-full">
                    <Divider />
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            <img src="/assets/images/umiejscowienieczakr.png" alt="Umiejscowienie czakr" width="100%" />
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Czakry zwykle kojarzą się z układem gruczołów dokrewnych. Ich położenie w ciele pokrywa się z usytuowaniem wielu z tych gruczołów, a znaczenie każdej czakry jest ściśle powiązane z funkcją odpowiadającego jej gruczołu.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            1. Czakram Podstawy (czakra korzenia)
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Kolor:</strong> <br />
                            Czerwony.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Położenie:</strong> <br />
                            Pomiędzy narządami rozrodczymi a odbytem.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Urzeczywistnienie:</strong> <br />
                            Zdrowie, witalność, energia, bezpieczeństwo, łączność z ziemią i naturą, ukorzenienie, stabilność, pewność, siła, odwaga, moc.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Blokady i dysharmonie:</strong> <br />
                            <ol>
                                <li>Chorobliwość, lękliwość, słabość, ospałość, lenistwo, brak pewności siebie, poczucie zagrożenia, poczucie oderwania od rzeczywistości.</li>
                                <li>Materializm, zaborczość, zachłanność, agresja, dominacja, przemoc, gwałtowność.</li>
                            </ol>
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Efekty nieprawidłowego działania czakramu widoczne w ciele:</strong> <br />
                            Choroby jelit, otyłość, anoreksja, choroby kości, prostaty, choroby krwi, nadciśnienie, niskie ciśnienie.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Uzależnienia:</strong> <br />
                            Hazard, przymus kupowania, wydawania pieniędzy, ryzykowne sporty, adrenalina, pracoholizm.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            2. Czakram Podbrzusza (czakra sakralna)
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Kolor:</strong> <br />
                            Pomarańczowy.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Położenie:</strong> <br />
                            Poniżej pępka, ponad kością łonową.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Urzeczywistnienie:</strong> <br />
                            Radość, przyjemność, seksualność, szacunek, inteligencja emocjonalna, siła życiowa, niezależność, swobodne przejawianie się, wolność, (drugie centrum mocy, energii i równowagi).
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Blokady i dysharmonie:</strong> <br />
                            <ol>
                                <li>Stłumienie, zastraszenie, poczucie winy, potępianie siebie, samoudręczenie, oskarżanie, brak inicjatywy, nieśmiałość, oziębłość, niemoc, wyparcie.</li>
                                <li>Potępianie innych, krytykanctwo, moralizatorstwo, hipokryzja, fałsz, zboczenia seksualne.</li>
                            </ol>
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Efekty nieprawidłowego działania czakramu widoczne w ciele:</strong> <br />
                            Choroby weneryczne, choroby narządów płciowych, krwi i układu limfatycznego, niektóre choroby skóry.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Uzależnienia:</strong> <br />
                            Alkohol, seks, heroina.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            3. Czakram Splotu Słonecznego
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Kolor:</strong> <br />
                            Żółty.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Położenie:</strong> <br />
                            Splot słoneczny, 2 palce powyżej pępka.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Urzeczywistnienie:</strong> <br />
                            Dostatek, szczęście, komfort, działanie, moc w działaniu, pracowitość, skuteczność, współdziałanie, optymizm, samoświadomość, akceptacja.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Blokady i dysharmonie:</strong> <br />
                            <ol>
                                <li>Niedbałość, nieskuteczność, brak chęci do pracy , izolowanie się, koncentracja na negatywach, narzekanie, niska samoocena.</li>
                                <li>Wybuchowość, tyranizowanie, postawa roszczeniowa, narzucanie się, domaganie uwagi, domaganie racji, konfliktowość.</li>
                            </ol>
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Efekty nieprawidłowego działania czakramu widoczne w ciele:</strong> <br />
                            Choroby przewodu pokarmowego, kolki, żółtaczka, nadkwasota, kamienie w woreczku żółciowym, cukrzyca, niektóre choroby skóry.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Uzależnienia:</strong> <br />
                            Amfetamina, kokaina, kofeina.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            4. Czakram Serca
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Kolor:</strong> <br />
                            Zielony i różowy.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Położenie:</strong> <br />
                            Serce.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Urzeczywistnienie:</strong> <br />
                            Harmonia, miłość, akceptowanie, zrozumienie, wybaczenie, łagodność, wrażliwość, uczuciowość, uzdrawianie, pojednanie, pomaganie.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Blokady i dysharmonie:</strong> <br />
                            <ol>
                                <li>Przewrażliwienie, histeria, lęki, noszenie urazy, pogrywanie na emocjach, negatywizm, dołki emocjonalne, depresje, brak wiary i nadziei w życiu, stłamszenie.</li>
                                <li>Chłód emocjonalny, okrucieństwo, sztywne podejście, dystans, mściwość, kłótliwość, interesowność.</li>
                            </ol>
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Efekty nieprawidłowego działania czakramu widoczne w ciele:</strong> <br />
                            Choroby klatki piersiowej, serca i płuc (astma), (większość raków = niewybaczanie, nienawiść, uraza, poczucie skrzywdzenia), alergie, bóle pleców.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Uzależnienia:</strong> <br />
                            Palenie, cukier, marihuana.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            5. Czakram Gardła
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Kolor:</strong> <br />
                            Błękitny.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Położenie:</strong> <br />
                            Pomiędzy gardłem a zagłębieniem między obojczykami.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Urzeczywistnienie:</strong> <br />
                            Twórczość, inteligencja analityczna, wiedza, nauka, komunikacja, samoekspresja, kariera, realizacja marzeń, dynamiczność, przebojowość, delikatność, szczerość, zdolności muzyczne.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Blokady i dysharmonie:</strong> <br />
                            <ol>
                                <li>Nieśmiałość, natręctwa, obsesje, nadmierne przywiązanie do autorytetów, brak własnego zdania, tchórzostwo.</li>
                                <li>Sarkazm, złośliwość, uszczypliwość, ukrywanie swojej prawdziwej twarzy, nadmierne racjonalizowanie, obsesyjne przywiązanie do naukowego poglądu na świat, pijaństwo, ciągłe szukanie kontaktu z innymi, niezdrowy tryb życia.</li>
                            </ol>
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Efekty nieprawidłowego działania czakramu widoczne w ciele:</strong> <br />
                            Choroby gardeł i uszu, karku, zębów, zapalenie nerwów twarzy.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Uzależnienia:</strong> <br />
                            Opiaty, marihuana.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            6. Czakram Trzeciego Oka
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Kolor:</strong> <br />
                            Indygo, szafirowy.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Położenie:</strong> <br />
                            Pomiędzy brwiami – do 1 cm ponad linia brwi.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Urzeczywistnienie:</strong> <br />
                            Mądrość, intuicja, wyobraźnia, jasnowidzenie, medytacja, zjednoczenie przeciwieństw, synergia, synteza, geniusz, twórczość, inspiracja, spokój, równowaga, charyzma, mistrzostwo w dowolnej dziedzinie, przywództwo, (a także wymienia się : moce siddhi, pamięć poprzednich wcieleń, świadome sny).
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Blokady i dysharmonie:</strong> <br />
                            <ol>
                                <li>Nieprzytomność, nierozsądek, niedojrzałość, uzależnienia, odloty, uciekanie od rzeczywistości, brak zainteresowań, uzależnienie od innych (rodziców, partnera), brak asertywności, słabość, wewnętrzny lęk, zahamowania.</li>
                                <li>Klapki na oczach, pomieszanie, schematyczność, presje na innych i na siebie, ograniczenie fantazji, wyobraźni, duma , manipulacja, konflikty z prawem, wyrachowanie, pustka duchowa, brak inspiracji, brak polotu, narzucanie zdania innym, przeginanie, przesadzanie, skrajny ateizm i materializm, przerost ego.</li>
                            </ol>
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Efekty nieprawidłowego działania czakramu widoczne w ciele:</strong> <br />
                            Choroby oczu (ślepota), zatok, nosa, kości czaszki, kręgów, mózgu, migreny, wrażenie ucisku w skroniach.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Uzależnienia:</strong> <br />
                            Halucynogeny.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            7. Czakram Korony
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Kolor:</strong> <br />
                            Fioletowy, biały (diamentowy), tęczowy.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Położenie:</strong> <br />
                            Na szczycie głowy.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Urzeczywistnienie:</strong> <br />
                            Rozumienie rzeczywistości w głęboki, duchowy sposób, transcendencja, mistyka, modlitwa, poczucie łączności z wszechświatem, boskością, szeroka świadomość, otwartość, zaufanie, nieosądzanie, oświecenie, łaska.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Blokady i dysharmonie:</strong> <br />
                            <ol>
                                <li>Zagubienie i poczucie dotkliwej samotności w świecie, między ludźmi, oddzielenie od życia, choroby psychiczne, iluzje, nihilizm, autodestrukcja, nerwice.</li>
                                <li>Paniczny strach, nieufność, wyniosłość, separowanie się, lęk przed śmiercią , przed niebytem, zamknięcie na duchowość, ucieczka w wewnętrzny świat, strach przed „ujawnieniem” innym tego kim jest się naprawdę.</li>
                            </ol>
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Efekty nieprawidłowego działania czakramu widoczne w ciele:</strong> <br />
                            Osłabienie, zniechęcenie, apatia, zaburzenia świadomości, zaburzenia pamięci, problemy psychologiczne, depresje.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Uzależnienia:</strong> <br />
                            Czarna magia, czarna tantra.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Rozwój:</strong> <br />
                            Jest otwarta pierwotnie u noworodków (ciemiączko), jednak potem powoli się zamyka. Może być w pełni otwarta ponownie po osiągnięciu duchowej dojrzałości. Przez tą czakrę energia, Dusza wchodzi a potem opuszcza ciało w chwili śmierci.
                        </Text>
                    </div>
                </Grid>
                <Grid item className="w-full">
                    <Divider />
                </Grid>
                <Grid item md={12}>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            <strong>Źródło, więcej informacji:</strong> <br />
                            <a href="https://zenforest.wordpress.com/2007/12/01/czakramy-poziomy-twojego-zycia/">zesforest.wordpress.pl</a>
                        </Text>
                    </div>
                </Grid>
            </Grid>
        </Page>
    )
}

export default DowsingPage;