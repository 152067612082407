import React, { Component } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  FormState,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  UseFormUnregister,
  UseFormWatch
} from 'react-hook-form';

export enum FormMode {
  VIEW = 'VIEW',
  CREATE = 'CREATE',
  EDIT = 'EDIT'
}

enum FieldTypeEnum {
  TEXT = 'TEXT',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  DICTIONARY = 'DICTIONARY',
  DICTIONARY_QUICK_CHANGEABLE = 'DICTIONARY_QUICK_CHANGEABLE',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  DATETIME = 'DATETIME'
}

type FieldName = { fieldName: string };

export type TextValidationProps = FieldName & {
  validation: any;
  fieldType: FieldTypeEnum.TEXT;
};

export type SelectValidationProps = FieldName & {
  isMultiple: false;
  validation: any;
  fieldType: FieldTypeEnum.AUTOCOMPLETE;
};

export type SelectMultipleValidationProps = FieldName & {
  isMultiple: true;
  validation: any;
  fieldType: FieldTypeEnum.AUTOCOMPLETE;
};

export type NumberValidationProps = FieldName & {
  validation: any;
  fieldType: FieldTypeEnum.NUMBER;
};

export type BooleanValidationProps = FieldName & {
  validation: any;
  fieldType: FieldTypeEnum.BOOLEAN;
};

export type DateValidationProps = FieldName & {
  validation: any;
  fieldType: FieldTypeEnum.DATETIME;
};

export type ValidationProperties =
  | TextValidationProps
  | SelectValidationProps
  | SelectMultipleValidationProps
  | NumberValidationProps
  | BooleanValidationProps
  | DateValidationProps;

interface FormV2ContextState {
  errors?: FieldErrors;
  register?: UseFormRegister<FieldValues>;
  unregister?: UseFormUnregister<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  watch?: UseFormWatch<FieldValues>;
  getValues?: UseFormGetValues<FieldValues>;
  setError?: UseFormSetError<FieldValues>;
  trigger?: UseFormTrigger<FieldValues>;
  additionalFields?: {
    [key: string]: Component;
  };
  submitCount?: number;
  control?: Control<FieldValues, any>;
  // TODO: do usunięcia po stworzeniu nowego select'a
  initialValues?: FieldValues;
  formMode?: FormMode;
  handleSubmit?: UseFormHandleSubmit<FieldValues>;
  formState?: FormState<FieldValues>;
  reset?: UseFormReset<FieldValues>;
  loading?: boolean;
  isSubmitting?: boolean;
  isSubmitted?: boolean;
  isDirty?: boolean;
  isPermissionKeys?: boolean;
  validationBuilderFunctions?: {
    setFieldValidation: (param: ValidationProperties) => void;
    handleUnmountField: (fieldName: string) => void;
  };
}

const FormV2Context = React.createContext<FormV2ContextState | any>({
  errors: {},
  register: null,
  unregister: null,
  setValue: null,
  watch: null,
  getValues: null,
  trigger: null,
  control: null,
  formState: null,
  isSubmitting: false,
  loading: false,
  isDirty: false,
  additionalFields: {},
  initialValues: null,
  submitCount: 0,
  formMode: FormMode.CREATE,
  handleSubmit: null,
  reset: null,
  isPermissionKeys: null
});

export default FormV2Context;
