import { Grid } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Page, Text } from "../../modules/common/components";
import { HeroFilm } from "../main/components";

function AboutPage() {
    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                &nbsp;/&nbsp;O nas
            </>
        )
    }, []);

    return (
        <Page title={title}>
            <Grid container spacing={3}>
                <Grid item md={6} lg={4}>
                    <HeroFilm />
                </Grid>
                <Grid item md={12} lg={8}>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Witam serdecznie. To już kolejny rok (od października 2019) kiedy Kroniki Akaszy otworzyły się „oficjalnie” dla mnie i mojego męża Marka. Wcześniej nieśmiało i nieco po omacku oddawaliśmy się prowadzeniu uniwersalnej energi. Jest taki dzień w życiu każdego człowieka, kiedy wie na pewno co kocha najmocniej i czego szukał tak długo… ta „meta” jest nowym „startem” w nowe życie, pełne zaufania i świadomości.
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            My już jesteśmy na tej nowej drodze… dołącz do nas jeśli chcesz...
                        </Text>
                    </div>
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <div data-aos="fade-up">
                                <Text variant="p" color="dark">
                                    <strong>Marek: </strong>
                                    <ul>
                                        <li>Radiestezja – J. Bujnowski (kurs),</li>
                                        <li>Bioenergoterapia – J. Stronczyński (kurs),</li>
                                        <li>Ustawienia systemowe – G. Halkiew (kurs zawodowy),</li>
                                        <li>Regresja Hiptnotyczna – G. Halkiew (kurs zawodowy),</li>
                                        <li>Kroniki Akaszy – Dana Lenarczyk (seminarium)</li>
                                        <li>pedagog dla prowadzących szkolenia (kurs),</li>
                                        <li>pierwsza pomoc przedmedyczna (kurs).</li>
                                    </ul>
                                </Text>
                            </div>
                        </Grid>
                        <Grid item md={6}>
                            <div data-aos="fade-up">
                                <Text variant="p" color="dark">
                                    <strong>Danka: </strong>
                                    <ul>
                                        <li>praca z wahadłem,</li>
                                        <li>Ustawienia systemowe – G. Halkiew (kurs zawodowy),</li>
                                        <li>Regresja Hiptnotyczna – G. Halkiew (kurs zawodowy),</li>
                                        <li>Kroniki Akaszy – Dana Lenarczyk (seminarium),</li>
                                        <li>pedagog dla prowadzących szkolenia (kurs),</li>
                                        <li>pierwsza pomoc przedmedyczna (kurs).</li>
                                    </ul>
                                </Text>
                            </div>  
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Page>
    );
}

export default AboutPage;