import FilmsPage from './FilmsPage';

const FilmsConfig = {
//   settings: {
//     layout: {
//       config: {
//         navbar: {
//           display: false
//         },
//         topBar: {
//           display: false
//         },
//         footer: {
//           display: false
//         }
//       }
//     }
//   },
  routes: [
    {
      path: '/filmy',
      component: FilmsPage
    }
  ]
};

export default FilmsConfig;
