import RegressionPage from "./RegressionPage";

const RegressionConfig = {
  routes: [
    {
      path: '/offer/regresja-hipnotyczna',
      component: RegressionPage
    }
  ]
};

export default RegressionConfig;
