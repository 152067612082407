import { QueryConfig, useQuery } from 'react-query';
import API from '../../../../api/endpoints';

const WORKSHOP_DETAILS = 'WORKSHOP_DETAILS';

const getEvents = async (params: any) => {
  const { data } = await API.getEvents(params.id);

  return data;
};

export const useWorkshopQuery = (params: any, queryConfig: QueryConfig<string, unknown> = {}) => {
  return useQuery([params, WORKSHOP_DETAILS], getEvents, queryConfig);
};
