import WorkshopsPage from './WorkshopsPage';

const WorkshopsConfig = {
  routes: [
    {
      path: '/warsztaty',
      component: WorkshopsPage
    }
  ]
};

export default WorkshopsConfig;
