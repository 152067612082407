import BreathingExercisesPage from './BreathingExercisesPage';

const BreathingExercisesConfig = {
  routes: [
    {
      path: '/materialy/cwiczenia-oddechowe',
      component: BreathingExercisesPage
    }
  ]
};

export default BreathingExercisesConfig;
