import { QueryConfig, useQuery } from 'react-query';
import API from '../../../../api/endpoints';

const EVENT_DETAILS = 'EVENT_DETAILS';

const getEvent = async (params: any) => {
  const { data } = await API.getEvent(params.id);

  return data;
};

export const useEventQuery = (params: any, queryConfig: QueryConfig<string, unknown> = {}) => {
  return useQuery([params, EVENT_DETAILS], getEvent, queryConfig);
};
