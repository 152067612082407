import { ButtonVariants } from "../../../modules/common/components/Button/Button";
import { BasicPaper } from "../components";
import { BasicPaperVariants } from "../components/BasicPaper";
import Text from '../../../modules/common/components/Text/Text';
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

interface IProps {
    isMainPage?: boolean;
}

function Materials({ isMainPage }: IProps) {
    const history = useHistory();
    const body = document.querySelector('#root');

    const navigateTo = (page: string) => {
        body?.scrollIntoView();
        history.push(page)
    }

    return (
        <section className={clsx(isMainPage ? "" : 'materials')}>
            <div className={clsx((isMainPage ? "" : "pt-5 container"))} data-aos="fade-up">
                <Text variant="h2" isBold color="bright">
                    Materiały
                </Text>
                <Swiper
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 50,
                        },
                        1000: {
                            slidesPerView: 2,
                            spaceBetween: 50,
                        },
                        1200: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                        1600: {
                            slidesPerView: 4,
                            spaceBetween: 50,
                        }
                    }}
                    modules={[Navigation]}
                    className={clsx(isMainPage ? "" : "pt-5 pb-2")}
                    grabCursor
                    navigation
                    loop
                >
                    <SwiperSlide>
                        <BasicPaper
                            variant={BasicPaperVariants.MATERIALS}
                            buttonVariant={ButtonVariants.SECONDARY}
                            title="Odczyty niespersonalizowane"
                            description="Zbiór uniwersalnych odczytów z Kronik Akaszy - znajdź swoją ścieżkę, zrozum symbole i metafory."
                            onClick={() => navigateTo(`/materialy/odczyty-niespersonalizowane`)}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <BasicPaper
                            variant={BasicPaperVariants.MATERIALS}
                            buttonVariant={ButtonVariants.SECONDARY}
                            title="7 Uniwersalnych Praw Wszechświata"
                            description="Bez względu na to, czy je znamy, czy nie, żyjemy wg. tych zasad… Kybalion – hermetyczny traktat z 1908 roku, autorstwa anonimowych osób, podpisujących się jako Trzej Wtajemniczeni. Kybalion przedstawia filozofię hermetyzmu i siedem praw, na których się ona opiera..."
                            onClick={() => navigateTo(`/materialy/7-praw-wszechswiata`)}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <BasicPaper
                            variant={BasicPaperVariants.MATERIALS}
                            buttonVariant={ButtonVariants.SECONDARY}
                            title="Świadomy oddech"
                            description="Nasze życie tuż po urodzeniu rozpoczyna się wdechem a kończy w czasie śmierci z wydechem. Oddech to życie. Prawidłowy oddech to jakość naszego życia. Na co dzień nie zwracamy uwagi w jaki sposób oddychamy, a okazuje się że poprzez świadomy oddech możemy rozładować każde napięcie nerwowe i stres bez względu na jego pochodzenie bądź podłoże."
                            onClick={() => navigateTo(`/materialy/cwiczenia-oddechowe`)}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <BasicPaper
                            variant={BasicPaperVariants.MATERIALS}
                            buttonVariant={ButtonVariants.SECONDARY}
                            title="Przykładowe Ustawienia Systemowe"
                            description="Zbiór przykładowych ustawień systemowych Naszych podpiecznych."
                            onClick={() => navigateTo(`/materialy/przykladowe-ustawienia-systemowe`)}
                        />
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    );
}

export default Materials;