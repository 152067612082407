import OfferPage from './OfferPage';

const OfferConfig = {
  routes: [
    {
      path: '/oferta',
      component: OfferPage
    }
  ]
};

export default OfferConfig;
