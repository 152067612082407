import { Divider, Grid } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Page, Text } from "../../../modules/common/components";

function BreathingExercisesPage() {
    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                <Link to="/materialy" className="white-font">
                 / Materiały
                </Link>
                / Świadomy oddech
            </>
        )
    }, []);

    return (
        <Page title={title}>
            <Grid container spacing={3}>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Poprzez oddech na wyższy poziom świadomości
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={6} />
                <Grid item md={12}>
                    <Divider />
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <img src="/assets/images/oddychanie.png" alt="Główna logo strony" width="200px" />
                        <Text variant="p" color="dark">
                            Nasze życie tuż po urodzeniu rozpoczyna się wdechem a kończy w czasie śmierci z wydechem. Oddech to życie. Prawidłowy oddech to jakość naszego życia. Na co dzień nie zwracamy uwagi w jaki sposób oddychamy, a okazuje się że poprzez świadomy oddech możemy rozładować każde napięcie nerwowe i stres bez względu na jego pochodzenie bądź podłoże. Podarujmy sobie kilka minut dziennie. Tylko sobie... nikomu innemu.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Świadomy oddech:
                        </Text>
                        <Text variant="p" color="dark">
                            <ul>
                                <li>zmniejsza złości i stany depresyjne,</li>
                                <li>poprawia sen,</li>
                                <li>wzmacnia odporność na stres,</li>
                                <li>zmniejsza skoki nastrojów  i impulsywność,</li>
                                <li>wzmacnia układ odpornościowy,</li>
                                <li>poprawia koncentracje i dotlenia głęboko komórki naszego ciała i mózg.</li>
                            </ul>
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Przykłady ćwiczeń:
                        </Text>
                        <Text variant="p" color="dark">
                            <ol>
                                <li>
                                    <strong>Głęboki brzuszny</strong> – stres, obniżenie ciśnienia krwi i pulsu – czas trwania to około 10 minut. Wykonaj głęboki wdech nosem (przeponowy) a wydech ustami. Wdech i wydech licz powoli do 7.
                                </li>
                                <li>
                                    <strong>Oddechy nosem</strong> – pobudzający i synchronizujący półkule mózgu (koncentracja). Palcem wskazującym przytykamy prawe nozdrze. Lewe wdech i wydech głęboko 7 razy. Palcem wskazującym przytykamy lewe nozdrze (zwalniając prawe). Prawa wdech i wydech głęboko 7 razy. Powtarzać 3 do 5 minut.
                                </li>
                                <li>
                                    <strong>Oddech równy</strong> – wyciszenie przed snem. Wdech i wydech równy, niegłęboki, niepłytki w miarę możliwości i klatka piersiowa i brzuch pracują synchronicznie Wdech i wydech liczymy do 4 średnio wolno.
                                </li>
                            </ol>
                        </Text>
                    </div>
                </Grid>
            </Grid>
        </Page>
    )
}

export default BreathingExercisesPage;