import SystemPage from './SystemPage';

const SystemConfig = {
//   settings: {
//     layout: {
//       config: {
//         navbar: {
//           display: false
//         },
//         topBar: {
//           display: false
//         },
//         footer: {
//           display: false
//         }
//       }
//     }
//   },
  routes: [
    {
      path: '/oferta/ustawienia-systemowe',
      component: SystemPage
    }
  ]
};

export default SystemConfig;
