import { Grid } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Page, Text } from "../../../modules/common/components";

function BioenergotherapyPage() {
    const title = useMemo(() => {
        return (
            <>
                <Link to="/" className="white-font">
                    Strona główna
                </Link>
                <Link to="/oferta" className="white-font">
                &nbsp;/&nbsp;Oferta
                </Link>
                &nbsp;/&nbsp;Bioenergoterapia
            </>
        )
    }, []);

    return (
        <Page title={title}>
            <Grid container spacing={3}>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Czym jest Bioenergoterapia?
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Bioenergoterapia to jedna z metod naturalnych prowadząca do polepszenia stanu zdrowia. Obszarem jej oddziaływania jest energetyczny system człowieka, jest on podobny do krwionośnego, lecz istnieje nie na planie fizycznym. Jest subtelną naturą energetyczną. W skład tego układu wchodzą czakry, czyli centra energetyczne. W całym ciele jest ich setki, jednak wyłonione spośród nich 7 podstawowych (szczegóły w zakładce radiestezja). To od tych głównych i od ich prawidłowego działania jest zależny poziom naszego funkcjonowania. To tam oraz w ciałach subtelnych: emocjonalnym i mentalnym (podświadomość), gromadzą się niekorzystne wzorce i kody blokujące prawidłowy przepływ energii życiowej. Powoduje w swoich następstwach dolegliwości ciała fizycznego.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Jakie są zasady zabiegu?
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Bioenergoterapeuta oczyszcza, udrażnia i nadaje prawidłowy kierunek energii. Korzysta ze swoich umiejętności przekazania uniwersalnej energii płynącej ze źródła wszystkiego co jest. Trzeba tu wspomnieć, że bioenergoterapeuta, który jest odbiornikiem, a potem przekaźnikiem energii, powinien dbać o swój własny system energetyczny. W czasie wykonywanego zabiegu powinien wykazywać wyższy poziom energetyczny od osoby potrzebującej zabiegu. Jest rzeczą prostą i zrozumiałą, że bioenergoterapeuta jest osobą bez nałogów i innych obciążeń i dolegliwości psychofizycznych. W czasie zabiegu klient jest informowany skąd pojawiły się u niego takie, czy inne dolegliwości i jak z tym pracować i oczyszczać się samemu w domu, aby kondycjonować ciało, Duszę i umysł. Współpraca i wykonanie zaleceń jest gwarancją poprawy zdrowia. Podstawą jest też „nie psucie sobie zdrowia”, a wtedy bioenergoterapeuta będzie mniej potrzebny.
                        </Text>
                    </div>
                </Grid>
                <Grid item md={6} lg={4}>
                    <div data-aos="fade-up">
                        <Text variant="h2" color="dark" isBold>
                            Jakie są plusy zabiegu?
                        </Text>
                    </div>
                    <div data-aos="fade-up">
                        <Text variant="p" color="dark">
                            Zabiegi bioenergoterapii prowadzą do ogólnego wzmocnienia całego organizmu, poprawy komfortu życia poprzez wyciszenie emocji, lepszy sen oraz pozytywne nastawienie do życia. Zatrzymanie postępu lub całkowite cofnięcie się choroby. Możliwe jest wczesne ostrzeganie, ponieważ w aurze człowieka widoczne są wcześniej, niż w ciele fizycznym. Bioenergoterapia nie jest magią, lecz połączeniem wysokoenergetycznych predyspozycji człowieka i jego wiedzy. Wymaga więc szczególnej koncentracji i samodyscypliny od osoby wykonującej zabieg. Klient decydujący się na zabieg robi to z własnej potrzeby, a nie za namową osób trzecich.
                        </Text>
                    </div>
                </Grid>
            </Grid>
        </Page>
    )
}

export default BioenergotherapyPage;