import AkashaPage from './AkashaPage';

const AkashaConfig = {
//   settings: {
//     layout: {
//       config: {
//         navbar: {
//           display: false
//         },
//         topBar: {
//           display: false
//         },
//         footer: {
//           display: false
//         }
//       }
//     }
//   },
  routes: [
    {
      path: '/oferta/kroniki-akaszy',
      component: AkashaPage
    }
  ]
};

export default AkashaConfig;
