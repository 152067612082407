import MaterialsPage from './MaterialsPage';

const MaterialsConfig = {
  routes: [
    {
      path: '/materialy',
      component: MaterialsPage
    }
  ]
};

export default MaterialsConfig;
