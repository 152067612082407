function HeroFilm() {
    return (
        <div className="responsive-iframe" data-aos="fade-up">
            <iframe 
                src="https://www.youtube.com/embed/tuPuFbCT4V8" 
                title="Hero Video" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                loading="lazy"
            >
            </iframe>
        </div>
    )
}

export default HeroFilm;