import SystemSettingExamplesPage from './SystemSettingExamplesPage';

const SystemSettingExamplesPageConfig = {
  routes: [
    {
      path: '/materialy/przykladowe-ustawienia-systemowe',
      component: SystemSettingExamplesPage
    }
  ]
};

export default SystemSettingExamplesPageConfig;
