// @ts-nocheck
export function setRoutes(config, defaultAuth?) {
  if (config.settings || config.auth) {
    const auth = config.auth ? (Array.isArray(config.auth) ? [...config.auth] : [config.auth]) : defaultAuth || null;
    return config.routes.map(route => {
      return {
        ...route,
        settings: { ...config.settings, ...route.settings },
        auth: params => {
          const arr = route.auth?.(params) || null;
          return auth && arr ? [...auth, arr] : auth || arr;
        }
      };
    });
  }

  return [...config.routes];
}

export function generateRoutesFromConfigs(configs: any[], defaultAuth?) {
  return configs.reduce((allRoutes: any[], config) => {
    allRoutes.push(...setRoutes(config, defaultAuth));
    return allRoutes;
  }, []);
}
